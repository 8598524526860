import React from "react";

const MyVacationsIcon = (props) => (
  <svg {...props} className={"icon"} width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.8483 27.1414L14.325 10.6401L18.8306 9.4314L35.0496 24.4114L47.3276 21.1214C48.2243 20.8813 49.1797 21.0072 49.9835 21.4715C50.7874 21.9358 51.3739 22.7004 51.614 23.5971C51.8541 24.4938 51.7282 25.4491 51.2639 26.253C50.7996 27.0568 50.035 27.6433 49.1383 27.8834L14.2036 37.2401L12.393 30.4781L12.9553 30.3264L18.7116 36.0314L12.5843 37.6741C12.0819 37.8088 11.5488 37.7717 11.0698 37.5688C10.5908 37.3659 10.1934 37.0087 9.94065 36.5541L3.82031 25.5267L7.20131 24.6214L12.9553 30.3264L24.8483 27.1391V27.1414ZM9.73298 44.3334H47.0663V49.0001H9.73298V44.3334Z"
      fill="var(--svgColor)"
    />
  </svg>
);

export default MyVacationsIcon;
