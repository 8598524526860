import React from "react";
import Container from "../container";
import { getAvatar, getUserData } from "src/app/selectors";
import { useSelector } from "react-redux";
import { getTasks } from "@icarius-pages/myDesktop/selectors";
import ProgressItem from "./progress/progressItem";

const Tasks = ({ small }) => {
  const avatar = useSelector(getAvatar);
  const { name } = useSelector(getUserData);
  const tasks = useSelector(getTasks);

  if (!tasks?.data?.length) return null;

  return (
    <Container title='Mis tareas'>
      <ProgressItem
        name={name}
        percentage={tasks?.progress || 0}
        avatar={avatar}
        data={tasks?.data || []}
        small={small}
      />
    </Container>
  )
}

export default Tasks;