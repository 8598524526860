import React from "react";
import { getAppColor, getAvatar, getUserData } from "src/app/selectors";
import { useSelector } from "react-redux";
import { Button, Tooltip, Typography, useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import paths from "@icarius-localization/paths";
import { LikeIcon, MailIcon } from "@icarius-icons/";
import { getLikes, getManager } from "@icarius-pages/myDesktop/selectors";
import ProfileImg from "@icarius-common/profileImg";

const FirstSection = ({ handleOpenMailDialog }) => {

  const color = useSelector(getAppColor);
  const avatar = useSelector(getAvatar);
  const manager = useSelector(getManager);
  const likes = useSelector(getLikes);
  const { level, name } = useSelector(getUserData);

  const history = useHistory();
  const isMoreThan600 = useMediaQuery(`(min-width: 600px)`);

  return (
    <div className="myDesktopUserDataContainer">
      <Tooltip title={level === "C" ? 'Abrir Datos Personales' : ''}>
        <div
          className="myDesktopUserDataContainerPhoto"
          onClick={() => level === "C" && history.push(paths.myPersonalData)}
          style={{ position: 'relative', paddingRight: 20, cursor: level === "C" && 'pointer' }}
        >
          <div style={{ marginRight: 25 }}>
            <ProfileImg
              size={100}
              image={avatar}
            />
          </div>
          <div className="myDesktopLikeContainer">
            <LikeIcon htmlColor={color} />
            <Typography>
              {likes > 99 ? "+99" : likes}
            </Typography>
          </div>
        </div>
      </Tooltip>
      <div className="myDesktopUserDataTextContainer" style={{ alignItems: isMoreThan600 ? '' : 'center' }}>
        <Typography className="whiteText myDesktopEmployeeName">
          {name}
        </Typography>
        <Typography className="whiteText myDesktopManagerName">
          {`Manager: ${manager?.name || "-"}`}
        </Typography>
        <Button
          onClick={handleOpenMailDialog}
          style={{ maxWidth: 290 }}
          className="whiteText"
          variant='contained'
          startIcon={<MailIcon htmlColor={color} />}
        >
          {'Enviar mensaje al manager'}
        </Button>
      </div>
    </div>
  )
}

export default FirstSection;
