import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLoading, getData } from "../selectors";
import { RESET_STATE } from "../actionTypes";
import { getMyApprovedConsentsAction } from "../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { useHistory } from "react-router-dom";
import paths from "@icarius-localization/paths";

const useMyApprovedConsents = () => {

  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [consentSelected, setConsentSelected] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const data = useSelector(getData);
  const isLoading = useSelector(getLoading);

  useEffect(() => {
    dispatch(getMyApprovedConsentsAction());
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch])

  const handleOpenDialog = (row) => {
    setConsentSelected(row.data);
    setDialogIsOpen(true);
  }

  const handleCloseDialog = () => {
    setDialogIsOpen(false);
    setConsentSelected(null);
  }

  const handlePreview = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    const consent = documents[0];

    if (consent.file && consent.code) {
      history.push(paths.myDigitalFolder);
    } else {
      dispatch(openSnackbarAction({ duration: 5000, msg: 'El consentimiento no tiene documento asociado', severity: "error" }));
    }
  }

  return {
    isLoading,
    data,
    dialogIsOpen,
    consentSelected,
    handleOpenDialog,
    handleCloseDialog,
    handlePreview,
  }
}

export default useMyApprovedConsents;