import React from "react";

const MyScheduleIcon = (props) => (
  <svg {...props} className={"icon"} width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M40.4648 6.99992H49.7982C50.417 6.99992 51.0105 7.24575 51.4481 7.68334C51.8857 8.12092 52.1315 8.71441 52.1315 9.33325V46.6666C52.1315 47.2854 
      51.8857 47.8789 51.4481 48.3165C51.0105 48.7541 50.417 48.9999 49.7982 48.9999H7.79818C7.17934 48.9999 6.58585 48.7541 6.14826 48.3165C5.71068 47.8789
      5.46484 47.2854 5.46484 46.6666V9.33325C5.46484 8.71441 5.71068 8.12092 6.14826 7.68334C6.58585 7.24575 7.17934 6.99992 7.79818
      6.99992H17.1315V2.33325H21.7982V6.99992H35.7982V2.33325H40.4648V6.99992ZM47.4648 25.6666H10.1315V44.3333H47.4648V25.6666ZM35.7982
      11.6666H21.7982V16.3333H17.1315V11.6666H10.1315V20.9999H47.4648V11.6666H40.4648V16.3333H35.7982V11.6666ZM14.7982 
      30.3333H19.4648V34.9999H14.7982V30.3333ZM26.4648 30.3333H31.1315V34.9999H26.4648V30.3333ZM38.1315 30.3333H42.7982V34.9999H38.1315V30.3333Z"
      fill="var(--svgColor)"
    />
  </svg>
);

export default MyScheduleIcon;
