import NAME from "./constants";

export const RESET_STATE = NAME + "/RESET_STATE";

export const GET = NAME + "/GET";
export const GET_REJECTED = NAME + "/GET_REJECTED";
export const GET_FULFILLED = NAME + "/GET_FULFILLED";

export const REQUEST = NAME + "/REQUEST";
export const REQUEST_REJECTED = NAME + "/REQUEST_REJECTED";
export const REQUEST_FULFILLED = NAME + "/REQUEST_FULFILLED";

export const GET_HISTORICAL = NAME + "/GET_HISTORICAL";
export const GET_HISTORICAL_REJECTED = NAME + "/GET_HISTORICAL_REJECTED";
export const GET_HISTORICAL_FULFILLED = NAME + "/GET_HISTORICAL_FULFILLED";