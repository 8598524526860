import NAME from "./constants";

export const GET = NAME + "/GET";
export const GET_FULFILLED = NAME + "/GET_FULFILLED";
export const GET_REJECTED = NAME + "/GET_REJECTED";

export const ACCEPT_MY_CONSENT = NAME + "/ACCEPT_MY_CONSENT";
export const ACCEPT_MY_CONSENT_FULFILLED = NAME + "/ACCEPT_MY_CONSENT_FULFILLED";
export const ACCEPT_MY_CONSENT_REJECTED = NAME + "/ACCEPT_MY_CONSENT_REJECTED";

export const PREVIEW_MY_CONSENT = NAME + "/PREVIEW_MY_CONSENT";
export const PREVIEW_MY_CONSENT_FULFILLED = NAME + "/PREVIEW_MY_CONSENT_FULFILLED";
export const PREVIEW_MY_CONSENT_REJECTED = NAME + "/PREVIEW_MY_CONSENT_REJECTED";