import * as actionTypes from "./actionTypes";
import {
  getMyDesktopAPI,
  acceptMyConsentAPI,
  previewMyConsentAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";
import { previewFromBlob } from "@icarius-utils/download";
import { getFileExtension, getFileName } from "@icarius-utils/fileUpload";
import { GET_KPI_INDICATORS_FULFILLED } from "@icarius-pages/kpiIndicators/actionTypes";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "INVALID_CONSENT":
      errorString = "Consentimiento ya aceptado.";
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getMyDesktopAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getMyDesktopAPI();

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: { ...data },
    });

    dispatch({
      type: GET_KPI_INDICATORS_FULFILLED,
      payload: {
        kpiIndicators: data.kpi,
        groupsToFilter: data.groupsToFilter,
      }
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const acceptMyConsentAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.ACCEPT_MY_CONSENT });
  try {
    let response = await acceptMyConsentAPI(dataToSend);

    let myConsents = response.data && response.data.myConsents;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.ACCEPT_MY_CONSENT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.ACCEPT_MY_CONSENT_FULFILLED,
      payload: { myConsents },
    });
    dispatch(openSnackbarAction({ msg: 'Muchas gracias por aceptar el consentimiento', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.ACCEPT_MY_CONSENT_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const previewMyConsentAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.PREVIEW_MY_CONSENT });
  try {
    let response = await previewMyConsentAPI(dataToSend);

    if (response.status !== 200) {
      dispatch({ type: actionTypes.PREVIEW_MY_CONSENT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    let title = response.headers["x-content-namefile"];
    previewFromBlob(response.data, getFileExtension(title), getFileName(title));

    dispatch({ type: actionTypes.PREVIEW_MY_CONSENT_FULFILLED });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.PREVIEW_MY_CONSENT_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};