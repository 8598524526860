import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import paths from "@icarius-localization/paths";
import { getQueries } from "@icarius-pages/myDesktop/selectors";
import ContainerTitle from "../containerTitle";
import { Typography, Grid } from "@material-ui/core";
import { FavouriteButton } from "@icarius-common/favouriteButton";

const Queries = ({ userRole, favouriteList, handleChangeFavourite }) => {

  const history = useHistory();

  const handleTitleClick = () => {
    history.push(userRole !== "E" ? paths.myQueries : paths.queries)
  }

  const handleItemClick = (group, queryCode) => {
    history.push({
      pathname: userRole !== "E" ? paths.myQueries : paths.queries,
      search: `?group=${group}&queryCode=${queryCode}`,
    });
  }

  const data = useSelector(getQueries);

  const hasFavourites = () => {
    let hasFavourites = false;

    Object.keys(data).forEach(group => {
      if (!hasFavourites) {
        const hasSomeFavourite = data[group].some(query => favouriteList.includes(query.code));
        if (hasSomeFavourite) hasFavourites = true;
      }
    })

    return hasFavourites;
  }

  if (!data || Object.keys(data).length === 0 || !hasFavourites()) return null;

  return (
    <Grid container direction="column">
      <ContainerTitle
        title='Mis consultas favoritas'
        variantAction={handleTitleClick}
        variant
      />
      {
        Object.keys(data).map(group => {
          // si el grupo no tiene ninguno favorito, no muestro nada
          if (data[group].every(item => !favouriteList.includes(item.code))) return null;
          return (
            <div key={group} style={{ marginTop: 20 }}>
              <Typography className="whiteText" style={{ marginLeft: 10 }}>
                {group}
              </Typography>
              <Grid container>
                {
                  data[group].map(query => {
                    // si no es favorito, no lo muestro
                    if (!favouriteList.includes(query.code)) return null;
                    return (
                      <Grid
                        key={query.code}
                        container item sm={12} md={6} lg={4}
                        style={{ position: 'relative', height: 160, cursor: 'pointer' }}
                      >
                        <Grid
                          container item xs={12}
                          className="myDesktopShadow"
                          onClick={() => handleItemClick(group, query.code)}
                          style={{ margin: 10, padding: 10, backgroundColor: 'var(--secondaryBackgroundColor)', borderRadius: 14 }}
                        >
                          <Grid container item xs={12} direction='column' justify="center" style={{ margin: 10 }}>
                            <Typography className="whiteText" style={{ fontSize: 18, fontWeight: 700 }}>
                              {group}
                            </Typography>
                            <Typography className="whiteText" style={{ fontSize: 20, fontWeight: 400 }}>
                              {query.name}
                            </Typography>
                          </Grid>
                        </Grid>
                        <div style={{ position: 'absolute', top: 20, right: 15 }}>
                          <FavouriteButton
                            type={"CON"}
                            code={query.code}
                            isFavourite={favouriteList.includes(query.code)}
                            handleChangeFavourite={handleChangeFavourite}
                          />
                        </div>
                      </Grid>
                    )
                  })
                }
              </Grid>
            </div>
          )
        })
      }
    </Grid>
  )
}

export default Queries;