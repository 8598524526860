import { useEffect } from "react";
import { getMyDesktopAction } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import useMyDesktopDialogs from "./useMyDesktopDialogs";
import { getPolls, getIsLoading as getIsLoadingHome } from "@icarius-pages/home/selectors";
import {
  getIsLoading,
  getTasks,
  getManager,
  getMyConsents,
  getHasMedalTable,
} from "../selectors";
import useFavourites from "@icarius-common/favouriteButton/components/useFavourites";
import { getIsLoadingMail } from "@icarius-pages/myPeople/selectors";
import useKPI from "@icarius-pages/kpiIndicators/components/useKPI";
import { getUserData } from "src/app/selectors";
import { getHomeDataAction } from "@icarius-pages/home/actions";

const useMyDesktop = () => {

  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const isLoadingHome = useSelector(getIsLoadingHome);
  const hasMedalTable = useSelector(getHasMedalTable);
  const polls = useSelector(getPolls);
  const tasks = useSelector(getTasks);
  const myConsents = useSelector(getMyConsents);
  const isSendingMail = useSelector(getIsLoadingMail);
  const manager = useSelector(getManager);
  const { code, level: role } = useSelector(getUserData);

  const pollsToAnswer = polls.filter(item => !item.isCompleted);

  const kpiGridDialogData = useKPI();

  const {
    myDesktopDialogsData,
    myDesktopDialogsFunctions,
  } = useMyDesktopDialogs();

  const {
    isLoadingFavourites: isLoadingKPIFavourites,
    favouriteList: favouriteKPIList,
    handleChangeFavourite: handleChangeKPIFavourite,
  } = useFavourites('KPI');

  const {
    isLoadingFavourites: isLoadingQueryFavourites,
    favouriteList: favouriteQueryList,
    handleChangeFavourite: handleChangeQueryFavourite,
  } = useFavourites('CON');

  const {
    isLoadingFavourites: isLoadingGeoFavourites,
    favouriteList: favouriteGeoList,
    handleChangeFavourite: handleChangeGeoFavourite,
  } = useFavourites('GEO');

  useEffect(() => {
    dispatch(getMyDesktopAction());
    dispatch(getHomeDataAction()); // para traer los polls actualizados
  }, [dispatch])

  const isLoadingDesktop = (
    isLoading
    || isLoadingHome
    || myDesktopDialogsData.isLoadingAssistance
    || isLoadingKPIFavourites
    || isLoadingQueryFavourites
    || isLoadingGeoFavourites
    || isSendingMail
    || kpiGridDialogData.loadingAnalytics
  );

  const myDesktopData = {
    ...myDesktopDialogsData,
    pollsToAnswer,
    tasks,
    isLoadingDesktop,
    manager,
    kpiGridDialogData,
    role,
    code,
    hasMedalTable,
    myConsents,
    favouriteKPIList: favouriteKPIList || [],
    favouriteQueryList: favouriteQueryList || [],
    favouriteGeoList: favouriteGeoList || [],
  }

  const myDesktopFunctions = {
    ...myDesktopDialogsFunctions,
    handleChangeKPIFavourite,
    handleChangeQueryFavourite,
    handleChangeGeoFavourite,
  }

  return {
    myDesktopData,
    myDesktopFunctions,
  }
}

export default useMyDesktop;
