import React from "react";

const ExtraHoursAuthIcon = (props) => (
  <svg {...props} className={"icon"} width="56" height="56" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 20.0533C7.82833 20.0533 4.44653 16.6715 4.44653 12.4999C4.44653 8.32821 7.82833 4.94641 12 4.94641C16.1717 4.94641 19.5535 8.32821 19.5535 12.4999C19.5489 16.6696 16.1698 20.0488 12 20.0533ZM12 6.4571C8.66267 6.4571 5.95723 9.16255 5.95723 12.4999C5.95723 15.8372 8.66267 18.5426 12 18.5426C15.3373 18.5426 18.0428 15.8372 18.0428 12.4999C18.039 9.1641 15.3358 6.46085 12 6.4571ZM15.7767 13.2552H11.2447L11.2447 8.72314L12.7553 8.72314L12.7553 11.7445H15.7767V13.2552Z" fill="var(--svgColor)" />
    <path d="M22.6529 16.3C22.5839 17.3403 22.0716 20.4555 18.3833 20.5561L18.3833 19.3401L16.1308 21.6001L18.3833 23.8601L18.3833 22.8394C18.3833 22.8394 24.1447 21.2691 22.7619 16.2898C22.7589 16.2767 22.7513 16.2652 22.7404 16.2573C22.7296 16.2495 22.7163 16.2458 22.703 16.2471C22.6896 16.2483 22.6772 16.2544 22.668 16.2641C22.6588 16.2738 22.6534 16.2866 22.6529 16.3Z" fill="var(--svgColor)" />
    <path d="M1.34686 8.70103C1.41589 7.66071 1.92816 4.54556 5.61649 4.44496L5.61649 5.66098L7.86902 3.40098L5.61649 1.14099L5.61649 2.16163C5.61649 2.16163 -0.144894 3.73123 1.23859 8.71123C1.24205 8.72378 1.24979 8.73471 1.26047 8.7421C1.27115 8.74948 1.28407 8.75287 1.29699 8.75165C1.3099 8.75044 1.32198 8.74469 1.3311 8.73544C1.34022 8.72618 1.3458 8.714 1.34686 8.70103Z" fill="var(--svgColor)" />
  </svg>
);

export default ExtraHoursAuthIcon;
