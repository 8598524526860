import React from "react";
import { getAppColor } from "src/app/selectors";
import { useSelector } from "react-redux";
import { Button, Typography } from "@material-ui/core";
import { WatchLaterIcon } from "@icarius-icons/";
import { getAssistanceData } from "src/app/selectors";
import { formatDate } from "@icarius-utils/date";
import { getLocalizedString } from "@icarius-localization/strings";

const SecondSection = ({ handleOpenRegisterAssistanceDialog }) => {

  const color = useSelector(getAppColor);
  const assistanceData = useSelector(getAssistanceData);

  if (!assistanceData.can_register_assistance) return null;

  const getRegisteredString = () => {
    if (assistanceData.latest_registered_date) {
      return `${formatDate(assistanceData.latest_registered_date)} ${assistanceData.latest_registered_time} hrs. - ${assistanceData.latest_registered_type === "exit" ? getLocalizedString("exit") : getLocalizedString("entrance")}`
    }
    return 'No hay asistencias registradas'
  }

  return (
    <div className="myDesktopUserDataContainer myDesktopHeaderAssistanceContainer">
      <div className="myDesktopUserDataTextContainer">
        <Typography className="whiteText myDesktopTimeTitle">
          {'Último registro de asistencia:'}
        </Typography>
        <Typography className="whiteText myDesktopTimeData">
          {getRegisteredString()}
        </Typography>
        <Button
          onClick={handleOpenRegisterAssistanceDialog}
          className="whiteText"
          variant='contained'
          startIcon={<WatchLaterIcon htmlColor={color} />}
        >
          {'Mi asistencia'}
        </Button>
      </div>
    </div>
  )
}

export default SecondSection;
