import { useState } from "react";
import * as yup from 'yup';
import { formatDateYYYYMMDD } from "@icarius-utils/date";

const useHandleForm = (data, submitCallback, invalidDataCallback) => {

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === 'date') {
        return new Date();
      }

      if (fieldName === 'recipient') {
        return "";
      }
    }

    const fieldNames = [
      'date',
      'recipient',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    const schema = yup.object().shape({
      'recipient': yup.string().required(),
      'date': yup.date().required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      let dataToSend = {
        code: data.code,
        date: formatDateYYYYMMDD(formData.date),
        recipient: formData.recipient,
      };

      submitCallback(dataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { formData, setFormValue, submit };
}

export default useHandleForm;
