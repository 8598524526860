import React from "react";

function CommunicationChannelIcon(props) {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg"  {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M27.1399 3.51816L30.6645 1.48328L31.6255 3.14784C31.651 3.18594 31.6755 3.22517 31.6988 3.26549L45.6143 27.3679L45.6223 27.3634L51.084 36.8233L47.5595 38.8581L46.5545 37.1175C46.5267 37.0765 46.5001 37.0342 46.475 36.9906L45.1553 34.7049L25.8693 38.1472L29.9602 45.2328C30.8059 46.6577 31.0353 48.3229 30.6063 49.9239C29.7359 53.1724 26.3849 55.1071 23.1364 54.2367C21.5355 53.8077 20.2081 52.7764 19.3969 51.3315L14.874 43.4977L11.9565 45.1821C9.03714 46.8677 5.31038 45.8691 3.62491 42.9497L1.59181 39.4282C-0.0897265 36.5157 0.911747 32.7782 3.82426 31.0966L15.2358 24.5082L29.4914 7.59097L27.1399 3.51816ZM32.6016 12.9781L31.6532 11.3354L19.0348 26.3097L23.7073 34.4028L42.9933 30.9604L32.6088 12.9739L32.6016 12.9781ZM9.9234 41.6608C8.95029 42.2227 7.70813 41.8898 7.14622 40.9166L5.11312 37.3952C4.55261 36.4244 4.88643 35.1786 5.85726 34.618L15.8166 28.868L19.8828 35.9107L9.9234 41.6608ZM26.4604 47.303C26.7446 47.7792 26.8221 48.3363 26.6786 48.8717C26.3885 49.9545 25.2715 50.5994 24.1887 50.3093C23.6532 50.1658 23.2096 49.8201 22.9393 49.3359C22.9345 49.3273 18.3951 41.4647 18.3951 41.4647L21.9157 39.4321C21.9157 39.4321 26.4553 47.2945 26.4604 47.303Z" fill="var(--svgColor)" />
      <rect x="47.063" y="3.69556" width="4.14327" height="8.799" transform="rotate(42.914 47.063 3.69556)" fill="var(--svgColor)" />
      <rect x="54.7458" y="14.6898" width="4.14327" height="9" transform="rotate(72.3797 54.7458 14.6898)" fill="var(--svgColor)" />
    </svg>

  );
}
export default CommunicationChannelIcon;
