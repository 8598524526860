import React from "react";

const ExtraHoursAuthIcon = (props) => (
  <svg {...props} className={"icon"} width="56" height="56" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6038_487)">
      <path d="M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C22.9933 18.0724 18.0724 22.9933 12 23ZM12 3.2C7.13989 3.2 3.2 7.13989 3.2 12C3.2 16.8601 7.13989 20.8 12 20.8C16.8601 20.8 20.8 16.8601 20.8 12C20.7945 7.14216 16.8578 3.20546 12 3.2ZM17.5 13.1H10.9L10.9 6.5H13.1L13.1 10.9H17.5L17.5 13.1Z" fill="var(--svgColor)" />
    </g>
    <defs>
      <clipPath id="clip0_6038_487">
        <rect width="24" height="24" fill="var(--svgColor)" />
      </clipPath>
    </defs>
  </svg>
);

export default ExtraHoursAuthIcon;
