import React from "react";

const OnboardingTrackingIcon = (props) => (
  <svg {...props} className={"icon"} width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.64478 22.2639H21.4999" stroke="var(--svgColor)" strokeWidth="2.3871" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M9.64478 31.2286H21.4999" stroke="var(--svgColor)" strokeWidth="2.3871" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M9.64478 40.0209H21.4999" stroke="var(--svgColor)" strokeWidth="2.3871" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M28.1511 21.6028L29.3787 23.0722L33.2143 19.9821" stroke="var(--svgColor)" strokeWidth="2.3871" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M28.1511 30.3902L29.3787 31.864L33.2143 28.7738" stroke="var(--svgColor)" strokeWidth="2.3871" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M28.1511 39.1824L29.3787 40.6562L33.2143 37.566" stroke="var(--svgColor)" strokeWidth="2.3871" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M31.7261 2.55469H12.1232C11.667 2.55469 11.2971 2.92452 11.2971 3.38074V10.2255C11.2971 10.6817 11.667 11.0516 12.1232 11.0516H31.7261C32.1823 11.0516 32.5522 10.6817 32.5522 10.2255V3.38074C32.5522 2.92452 32.1823 2.55469 31.7261 2.55469Z" stroke="var(--svgColor)" strokeWidth="1.23907" strokeMiterlimit="10" />
    <path d="M17.0093 6.63037H26.8383" stroke="var(--svgColor)" strokeWidth="1.23907" strokeMiterlimit="10" strokeLinecap="round" />
    <mask id="path-9-inside-1_7419_582" fill="var(--svgColor)">
      <rect x="10.1611" y="1.98389" width="23.871" height="9.54839" rx="1.19355" />
    </mask>
    <rect x="10.1611" y="1.98389" width="23.871" height="9.54839" rx="1.19355" stroke="var(--svgColor)" strokeWidth="4.77419" mask="url(#path-9-inside-1_7419_582)" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.19359 4.96777C2.54564 4.96777 1.20972 6.3037 1.20972 7.95164V50.3226C1.20972 51.9706 2.54564 53.3065 4.19359 53.3065H38.8065C40.4544 53.3065 41.7904 51.9706 41.7904 50.3226V49.2253C40.3937 48.6075 39.1694 47.671 38.2097 46.5081V49.7258H4.79036V8.54842H10.7581V4.96777H4.19359ZM38.2097 34.1138C39.1694 32.9508 40.3937 32.0143 41.7904 31.3965V7.95165C41.7904 6.3037 40.4544 4.96777 38.8065 4.96777H33.4355V8.54842H38.2097V34.1138Z" fill="var(--svgColor)" />
    <path fillRule="evenodd" clipRule="evenodd" d="M45.728 50.0538C51.1089 50.0538 55.4709 45.6918 55.4709 40.3109C55.4709 34.93 51.1089 30.568 45.728 30.568C40.3472 30.568 35.9851 34.93 35.9851 40.3109C35.9851 45.6918 40.3472 50.0538 45.728 50.0538ZM40.5599 45.9094H41.7084C41.7084 45.9094 42.9946 45.9114 44.5796 45.9094H46.8766C48.4615 45.9114 49.7478 45.9094 49.7478 45.9094H50.8963V45.3201C50.8938 43.0427 49.0956 41.1971 46.8766 41.1945H44.5796C42.3606 41.1971 40.5624 43.0427 40.5599 45.3201V45.9094ZM42.8569 37.6584C42.8569 39.2859 44.1424 40.6052 45.7281 40.6052C47.313 40.6032 48.5974 39.285 48.5993 37.6584C48.5993 36.0309 47.3138 34.7116 45.7281 34.7116C44.1424 34.7116 42.8569 36.0309 42.8569 37.6584Z" fill="var(--svgColor)" />
  </svg>
);

export default OnboardingTrackingIcon;
