import React from "react";
import { useHistory } from "react-router-dom";
import paths from "@icarius-localization/paths";
import Container from "../container";
import GridDialog from "@icarius-pages/kpiIndicators/components/gridDialog";
import Charts from "@icarius-pages/kpiIndicators/components/charts";
import Loader from "@icarius-common/loader";

const KPI = ({ kpiGridDialogData, favouriteList, handleChangeFavourite }) => {

  const history = useHistory();

  const {
    isLoading,
    gridTheme,
    color,
    locale,
    dateFormat,
    chartTitle,
    kpiIndicators,
    groupsToFilter,
    gridModalIsOpen,
    gridTypes,
    gridData,
    firstLevelData,
    secondLevelData,
    thirdLevelData,
    handleGoBackOneLevel,
    handleChartClick,
    handleCloseDialog,
    getKPIAdditionalDataAction,
  } = kpiGridDialogData;

  const handleTitleClick = () => {
    history.push(paths.kpiIndicators)
  }

  const hasFavourites = () => {
    let hasFavourites = false;

    Object.keys(kpiIndicators).forEach((group) => {
      if (!hasFavourites) {
        const hasSomeFavourite = kpiIndicators[group].some(query => favouriteList.includes(query.code));
        if (hasSomeFavourite) hasFavourites = true;
      }
    })

    return hasFavourites;
  }

  if (!kpiIndicators || Object.keys(kpiIndicators).length === 0 || !hasFavourites()) return null;

  return (
    <Container
      title='Mis KPI favoritos'
      variantAction={handleTitleClick}
      variant
    >
      <Loader open={isLoading} />
      <Charts
        color={color}
        theme={gridTheme}
        data={kpiIndicators}
        groupsToFilter={groupsToFilter}
        favouriteList={favouriteList}
        handleChangeFavourite={handleChangeFavourite}
        firstLevelData={firstLevelData}
        secondLevelData={secondLevelData}
        thirdLevelData={thirdLevelData}
        handleChartClick={handleChartClick}
        handleGoBack={handleGoBackOneLevel}
        getKPIAdditionalDataAction={getKPIAdditionalDataAction}
        showOnlyFavourites
      />
      {
        Object.keys(gridData).length > 0 &&
        <GridDialog
          open={gridModalIsOpen}
          data={gridData}
          types={gridTypes}
          title={chartTitle}
          locale={locale}
          dateFormat={dateFormat || "dd/mm/yyyy"}
          handleClose={handleCloseDialog}
        />
      }
    </Container>
  )
}

export default KPI;