import React from "react";
import CommonPage from "@icarius-common/commonPage";
import { MedalTable } from "@icarius-common/medalTable";
import { Button, Typography, useMediaQuery } from "@material-ui/core";
import ConsentItem from "@icarius-pages/myDesktop/components/sections/consents/consentItem";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import RequestBenefitDialog from "./dialogs/requestBenefitDialog";
import MyBenefitsHistoricalDialog from "./dialogs/myBenefitsHistoricalDialog";
import useMyBenefits from "./useMyBenefits";
import BenefitsList from "./benefitsList";

const MyBenefits = () => {

  const {
    userCode,
    data,
    historicalData,
    consent,
    recipientsList,
    exchangeForMoneyData,
    isLoading,
    state,
    handlers,
  } = useMyBenefits();

  const color = useSelector(getAppColor);
  const isSmallSize = useMediaQuery('(max-width: 1280px)');

  return (
    <div
      style={{
        width: isSmallSize ? '100%' : '85%',
        margin: '0 auto',
      }}
    >
      <CommonPage
        title={'Mis beneficios'}
        isLoading={isLoading}
        isNotGridPage
      >
        <MedalTable userCode={userCode} />
        {
          (Boolean(consent) || Boolean(data)) &&
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              style={{ margin: '0 auto', background: color, color: 'white' }}
              onClick={handlers.handleOpenHistoricalDialog}
            >
              Ver mis beneficios utilizados
            </Button>
          </div>
        }
        {
          Boolean(consent) ?
            <div style={{ width: '100%', maxWidth: 600, paddingTop: 20, margin: '0 auto' }}>
              <Typography className="whiteText" align="center" style={{ fontSize: 20, padding: '0px 20px' }}>
                {'Para el acceso a los beneficios, es necesario que revise y acepte el consentimiento de uso'}
              </Typography>
              <ConsentItem
                data={consent}
                isLoading={isLoading}
              />
            </div>
            :
            <BenefitsList
              data={data}
              exchangeForMoneyData={exchangeForMoneyData}
              handleRequest={handlers.handleRequest}
            />
        }
        {
          state.requestDialogIsOpen &&
          <RequestBenefitDialog
            open={state.requestDialogIsOpen}
            data={state.selectedBenefit}
            recipientsList={recipientsList}
            isLoading={isLoading}
            handleSubmit={handlers.submitRequest}
            handleClose={handlers.handleCloseRequestDialog}
          />
        }
        {
          state.historicalDialogIsOpen &&
          <MyBenefitsHistoricalDialog
            open={state.historicalDialogIsOpen}
            data={historicalData}
            handleClose={handlers.handleCloseHistoricalDialog}
          />
        }
      </CommonPage>
    </div>
  )
}

export default MyBenefits;