import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  hasLoans: false,
  hasMedalTable: false,
  likes: 0,
  myRequestsAmount: 0,
  myPendingAmount: 0,
  requestedDocumentsAmount: 0,
  hoursChangedAmount: 0,
  myActionsAmount: 0,
  feedbackAmount: 0,
  communicationsAmount: 0,
  pendingCommunicationsAmount: 0,
  onboardingTrackingAmount: 0,
  hoursChangedMinDate: '',
  extraHours: null,
  manager: null,
  queries: null,
  geo: null,
  alerts: [],
  tasks: [],
  progress: [],
  annotations: [],
  myConsents: [],
  mostVisitedPages: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET:
      return { ...state, isLoading: true };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        hasLoans: action.payload.hasLoans,
        manager: action.payload.manager,
        likes: action.payload.likes,
        mostVisitedPages: action.payload.mostVisitedPages,
        alerts: action.payload.alerts,
        annotations: action.payload.annotations,
        queries: action.payload.myQueries,
        requestedDocumentsAmount: action.payload.requestedDocumentsAmount,
        myRequestsAmount: action.payload.myRequestsAmount,
        myPendingAmount: action.payload.myPendingAmount,
        hoursChangedAmount: action.payload.hoursChangedAmount,
        hoursChangedMinDate: action.payload.hoursChangedMinDate || '',
        extraHours: action.payload.extraHours,
        myActionsAmount: action.payload.myActionsAmount,
        feedbackAmount: action.payload.feedbackAmount,
        communicationsAmount: action.payload.communicationsAmount,
        pendingCommunicationsAmount: action.payload.pendingCommunicationsAmount,
        tasks: action.payload.myTasks,
        progress: action.payload.progress,
        geo: action.payload.geo,
        myConsents: action.payload.myConsents,
        hasMedalTable: action.payload.hasMedalTable,
        onboardingTrackingAmount: action.payload.onboardingTrackingAmount,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.ACCEPT_MY_CONSENT:
      return { ...state, isLoading: true };
    case actionTypes.ACCEPT_MY_CONSENT_FULFILLED:
      return {
        ...state,
        isLoading: false,
        myConsents: action.payload.myConsents,
      };
    case actionTypes.ACCEPT_MY_CONSENT_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.PREVIEW_MY_CONSENT:
      return { ...state, isLoading: true };
    case actionTypes.PREVIEW_MY_CONSENT_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.PREVIEW_MY_CONSENT_REJECTED:
      return { ...state, isLoading: false };

    default:
      return state;
  }
}
