import React from "react";
import { getSocietySelected } from "@icarius-pages/login/selectors";
import { Grid, Typography, useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getSocietyTimezone } from "src/app/selectors";
import { getLanguage } from "@icarius-localization/strings";
import { getTime } from "@icarius-common/clock/selectors";

const toTitleCase = (str) => {
  return str.replace(
    /\w\S*/g,
    function (txt) {
      if (txt === "de") return txt;
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

const HeaderClock = () => {

  const societySelected = useSelector(getSocietySelected);
  const societyTimezone = useSelector(getSocietyTimezone);
  const time = useSelector(getTime);

  const timezone = societyTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  const language = getLanguage() !== "es" ? "en-US" : "es-ES";

  const isMoreOrEqualsThan960 = useMediaQuery(`(min-width: 960px)`);

  return (
    <Grid container justify="space-between" style={{ padding: '10px 10px 16px' }}>
      <Grid item sm={12} md={6}>
        <Typography className="whiteText myDesktopClockText">{societySelected.name}</Typography>
      </Grid>
      <Grid container item sm={12} md={6} justify={isMoreOrEqualsThan960 ? "flex-end" : "flex-start"}>
        <Typography className="whiteText myDesktopClockText myDesktopClockTextAlt">
          {
            toTitleCase(new Date(time).toLocaleDateString(language, {
              timeZone: timezone,
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            }))
          }
          ,
        </Typography>
        <Typography className="whiteText myDesktopClockText">
          {
            new Date(time).toLocaleTimeString("en-US", {
              timeZone: timezone,
              hour12: false,
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            })
          }
        </Typography>
      </Grid>
    </Grid>
  )
}

export default HeaderClock;