import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getMyAnnotationsAPI } from "@icarius-connection/api";
import { getLocalizedErrorString } from "@icarius-localization/strings";

export const getMyAnnotationRowsFromServer = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_MY_ANNOTATION_ROWS });
  try {
    let response = await getMyAnnotationsAPI();

    let rows = response.data && response.data.annotations;
    let categories = response.data && response.data.categorias;
    let types = response.data && response.data.tipos;
    let subtypes = response.data && response.data.subtipos;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_MY_ANNOTATION_ROWS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_MY_ANNOTATION_ROWS_FULFILLED,
      payload: { rows, categories, types, subtypes },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_MY_ANNOTATION_ROWS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};
