import React from "react";

const AbsentCollaboratorsIcon = (props) => (
  <svg {...props} className={"icon"} width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.6667 9.83329H16.8333V5.16663H20.5V9.83329H35.5V5.16663H39.1667V9.83329H44.3333C46.6345 9.83329 48.5 11.6988 48.5 14V46.6666C48.5 48.9678 46.6345 50.8333 44.3333 50.8333H11.6667C9.36548 50.8333 7.5 48.9678 7.5 46.6666V14C7.5 11.6988 9.36548 9.83329 11.6667 9.83329ZM44.8333 22.8333H11.1667V47.1666H44.8333V22.8333ZM44.8333 13.5H11.1667V19.1666H44.8333V13.5ZM25.4078 35L20.0574 29.6496L22.6497 27.0574L27.9999 32.4077L33.3503 27.0597L35.9425 29.6519L30.5921 34.9999L35.9426 40.3503L33.3525 42.9403L27.9998 37.5923L22.6486 42.9436L20.0573 40.3504L25.4078 35Z" fill="var(--svgColor)" />
    </svg>
  </svg>
);

export default AbsentCollaboratorsIcon;
