import React from "react";
import moment from "moment";
import { Grid, Typography, Tooltip } from "@material-ui/core";
import AvatarWithProgress, { getColorByPercentage } from "./avatarWithProgress";

const getFormattedProgress = (value = 0) => {
  return Math.round((value + Number.EPSILON) * 100) / 100
}

const ProgressItem = ({ name, percentage, avatar, data, small }) => {
  return (
    <Grid container style={{ marginTop: 20 }}>
      <Grid container item sm={12} md={small ? 4 : 2} justify='center'>
        <AvatarWithProgress
          name={name}
          percentage={getFormattedProgress(percentage)}
          avatar={avatar}
        />
      </Grid>
      <Grid container item sm={12} md={small ? 8 : 10} style={{ padding: 10 }}>
        <div style={{ display: 'flex', flexDirection: 'column', maxHeight: 460, overflow: 'auto', width: '100%' }}>
          {
            data.map((item, index) => {
              const remainingDays = item.endDate ? parseInt(moment.duration(moment(item.endDate, "DD/MM/YYYY").diff(moment(new Date()))).asDays()) + 1 : 0;
              return (
                <div key={index} style={{ paddingLeft: 20, paddingBottom: 20 }}>
                  <Typography className="whiteText" style={{ fontWeight: 500, fontSize: '1.1rem' }}>
                    {item.noteName}
                  </Typography>
                  <div style={{ display: 'flex' }}>
                    {
                      item.endDate ?
                        <Tooltip
                          title={
                            <>
                              <Typography
                                align="center"
                                style={{
                                  fontSize: "1rem",
                                  margin: "5px 0px 10px 0px",
                                  fontWeight: 600,
                                }}
                              >
                                {`Fecha de término: ${item.endDate}`}
                              </Typography>
                              <Typography style={{ fontSize: '0.95rem', fontWeight: 400, marginBottom: 5 }}>
                                {remainingDays === 1 ? 'Queda 1 día' : remainingDays < 0 ? `Atraso de ${(remainingDays - 1) * (-1)} días` : `Quedan ${remainingDays} días`}
                              </Typography>
                            </>
                          }
                          arrow
                        >
                          <div>
                            <Typography style={{ paddingRight: 8, fontWeight: 600, color: getColorByPercentage(getFormattedProgress(item.progress)) }}>
                              {getFormattedProgress(item.progress)}%
                            </Typography>
                            <Typography className="whiteText">
                              {item.taskName}
                            </Typography>
                          </div>
                        </Tooltip> :
                        <>
                          <Typography style={{ paddingRight: 8, fontWeight: 600, color: getColorByPercentage(getFormattedProgress(item.progress)) }}>
                            {getFormattedProgress(item.progress)}%
                          </Typography>
                          <Typography className="whiteText">
                            {item.taskName}
                          </Typography>
                        </>
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      </Grid >
    </Grid >
  )
}

export default ProgressItem;