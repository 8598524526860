import React from "react";

function MyBenefitsIcon(props) {
  return (
    <svg {...props} width="56" height="56" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.8269 15.4487C3.41269 15.4487 3.0769 15.7845 3.0769 16.1987C3.0769 16.6129 3.41269 16.9487 3.8269 16.9487V15.4487ZM5.31351 
        16.1987V16.9487H5.60986L5.82617 16.7461L5.31351 16.1987ZM14.0117 15.8816L13.4082 16.3269L13.6331 16.6316H14.0117V15.8816ZM10.311 
        17.6367C9.89683 17.6367 9.56104 17.9725 9.56104 18.3867C9.56104 18.8009 9.89683 19.1367 10.311 19.1367V17.6367ZM3.89003 21.8859C3.47582 
        21.8859 3.14003 22.2217 3.14003 22.6359C3.14003 23.0501 3.47582 23.3859 3.89003 23.3859V21.8859ZM17.0164 22.6359L17.0164 
        21.8859H17.0164V22.6359ZM20.2743 21.209L20.8542 21.6846V21.6846L20.2743 21.209ZM23.911 16.2552L24.5232 16.6884V16.6884L23.911 
        16.2552ZM21.6415 14.6406L21.0535 14.175V14.175L21.6415 14.6406ZM16.4025 17.6277C15.9883 17.6277 15.6525 17.9635 15.6525 18.3777C15.6525 
        18.7919 15.9883 19.1277 16.4025 19.1277V17.6277ZM23.7597 14.4926L23.2287 15.0222V15.0222L23.7597 14.4926ZM3.8269 
        16.9487H5.31351V15.4487H3.8269V16.9487ZM5.82617 16.7461C6.64366 15.9805 8.0158 15.0662 9.45111 14.7671C10.1577 14.6199 10.8546 
        14.6264 11.507 14.8472C12.1529 15.0657 12.8064 15.5112 13.4082 16.3269L14.6152 15.4363C13.8506 14.4 12.9537 13.7531 11.9877 13.4263C11.0281 
        13.1016 10.0514 13.1098 9.14508 13.2987C7.35466 13.6718 5.74409 14.7679 4.80084 15.6512L5.82617 16.7461ZM14.0117 
        16.6316H16.4472V15.1316H14.0117V16.6316ZM16.8675 17.0519V17.1341H18.3675V17.0519H16.8675ZM16.365 17.6367H10.311V19.1367H16.365V17.6367ZM16.8675 
        17.1341C16.8675 17.4117 16.6425 17.6367 16.365 17.6367V19.1367C17.471 19.1367 18.3675 18.2401 18.3675 17.1341H16.8675ZM16.4472 16.6316C16.6794 
        16.6316 16.8675 16.8198 16.8675 17.0519H18.3675C18.3675 15.9913 17.5078 15.1316 16.4472 15.1316V16.6316ZM3.89003 
        23.3859H17.0164V21.8859H3.89003V23.3859ZM17.0164 23.3859C17.447 23.3859 18.1114 23.3009 18.8034 23.0583C19.4962 22.8153 20.2717 22.3948 
        20.8542 21.6846L19.6944 20.7333C19.3406 21.1647 18.8352 21.4576 18.3071 21.6428C17.7781 21.8282 17.2817 21.8859 17.0164 21.8859L17.0164 
        23.3859ZM20.8542 21.6846C21.6443 20.7212 23.3665 18.3233 24.5232 16.6884L23.2987 15.822C22.1304 17.4733 20.443 19.8206 19.6944 20.7333L20.8542 
        21.6846ZM21.0535 14.175C20.3999 15.0005 19.6162 15.8848 18.7799 16.557C17.929 17.2411 17.1196 17.6277 16.4025 17.6277V19.1277C17.6284 19.1277 
        18.7708 18.489 19.7198 17.7261C20.6834 16.9515 21.5477 15.9672 22.2295 15.1062L21.0535 14.175ZM24.2907 13.9629C23.3555 13.0253 21.8436 13.1772 
        21.0535 14.175L22.2295 15.1062C22.4945 14.7715 22.9636 14.7565 23.2287 15.0222L24.2907 13.9629ZM24.5232 16.6884C25.1217 15.8425 25.0189 14.6929 
        24.2907 13.9629L23.2287 15.0222C23.445 15.2391 23.4726 15.5762 23.2987 15.822L24.5232 16.6884Z"
        fill="var(--svgColor)"
      />
      <rect x="0.877441" y="14.5017" width="3.56323" height="9.55446" rx="10" fill="var(--svgColor)" stroke="var(--svgColor)" strokeWidth="3.56323" />
      <path d="M15.3154 0.93286C15.5072 0.755019 15.8036 0.755019 15.9953 0.93286L16.4821 1.38426C16.8051 1.68378 17.2434 1.82619 17.6808 1.77372L18.3399 1.69465C18.5996 1.66349 18.8394 1.83772 18.89 2.09432L19.0185 2.74563C19.1037 3.17779 19.3746 3.55063 19.7593 3.76525L20.339 4.0887C20.5674 4.21613 20.659 4.49805 20.5491 4.73539L20.2702 5.33782C20.0852 5.73755 20.0852 6.19842 20.2702 6.59815L20.5491 7.20058C20.659 7.43792 20.5674 7.71984 20.339 7.84727L19.7593 8.17072C19.3746 8.38534 19.1037 8.75818 19.0185 9.19034L18.89 9.84165C18.8394 10.0982 18.5996 10.2725 18.3399 10.2413L17.6808 10.1622C17.2434 10.1098 16.8051 10.2522 16.4821 10.5517L15.9953 11.0031C15.8036 11.181 15.5072 11.181 15.3154 11.0031L14.8286 10.5517C14.5056 10.2522 14.0673 10.1098 13.63 10.1622L12.9708 10.2413C12.7112 10.2725 12.4714 10.0982 12.4207 9.84165L12.2923 9.19034C12.207 8.75818 11.9361 8.38534 11.5515 8.17072L10.9717 7.84727C10.7433 7.71984 10.6517 7.43792 10.7616 7.20058L11.0405 6.59815C11.2256 6.19842 11.2256 5.73755 11.0405 5.33782L10.7616 4.73539C10.6517 4.49805 10.7433 4.21613 10.9717 4.0887L11.5515 3.76525C11.9361 3.55063 12.207 3.17779 12.2923 2.74563L12.4207 2.09432C12.4714 1.83772 12.7112 1.66349 12.9708 1.69465L13.63 1.77372C14.0673 1.82619 14.5056 1.68378 14.8286 1.38426L15.3154 0.93286Z" stroke="var(--svgColor)" />
      <circle cx="15.6554" cy="5.96799" r="3.1329" fill="var(--svgColor)" />
    </svg>
  );
}
export default MyBenefitsIcon;
