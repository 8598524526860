import React from "react";
import PollItem from "@icarius-pages/home/components/slider/items/pollItem";
import Container from "../container";
import { Grid } from "@material-ui/core";
import { openPollAction } from "@icarius-common/poll/actions";
import { useDispatch } from "react-redux";
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";

const Polls = ({ data, small }) => {

  const dispatch = useDispatch();

  const handleClick = (item) => {
    dispatch(openPollAction(item.code, item.text, item.type));
  };

  return (
    <Container title='Encuestas pendientes'>
      <Grid container style={{ height: 460, overflow: 'auto' }}>
        {
          data.map((item) => {
            return (
              <Grid key={item.code} item xs={12} sm={6} md={small ? 12 : 6} lg={small ? 6 : 4} style={{ padding: 12 }}>
                <PollItem
                  name={item.name}
                  text={item.text}
                  image={item.image ? IMAGES_ENDPOINT + item.image : null}
                  points={item.points}
                  onClick={() => handleClick(item)}
                  isCompleted={false}
                  isDesktopVersion
                />
              </Grid>
            )
          })
        }
      </Grid>
    </Container>
  )
}

export default Polls;