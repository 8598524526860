import * as actionTypes from "./actionTypes";
import {
  ACCEPT_MY_CONSENT,
  ACCEPT_MY_CONSENT_FULFILLED,
  ACCEPT_MY_CONSENT_REJECTED,
  PREVIEW_MY_CONSENT,
  PREVIEW_MY_CONSENT_FULFILLED,
  PREVIEW_MY_CONSENT_REJECTED,
} from "@icarius-pages/myDesktop/actionTypes";

const initialState = {
  data: null,
  historicalData: null,
  consent: null,
  recipientsList: null,
  exchangeForMoneyData: null,
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET:
      return { ...state, isLoading: true };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        consent: action.payload.consent,
        recipientsList: action.payload.recipientsList,
        exchangeForMoneyData: action.payload.exchangeForMoneyData,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.REQUEST:
      return { ...state, isLoading: true };
    case actionTypes.REQUEST_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        consent: action.payload.consent,
        recipientsList: action.payload.recipientsList,
        exchangeForMoneyData: action.payload.exchangeForMoneyData,
      };
    case actionTypes.REQUEST_REJECTED:
      return { ...state, isLoading: false };

    case ACCEPT_MY_CONSENT:
      return { ...state, isLoading: true };
    case ACCEPT_MY_CONSENT_FULFILLED:
      return {
        ...state,
        isLoading: false,
        consent: null,
      };
    case ACCEPT_MY_CONSENT_REJECTED:
      return { ...state, isLoading: false };

    case PREVIEW_MY_CONSENT:
      return { ...state, isLoading: true };
    case PREVIEW_MY_CONSENT_FULFILLED:
      return { ...state, isLoading: false };
    case PREVIEW_MY_CONSENT_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_HISTORICAL:
      return { ...state, isLoading: true };
    case actionTypes.GET_HISTORICAL_FULFILLED:
      return {
        ...state,
        isLoading: false,
        historicalData: action.payload.data,
      };
    case actionTypes.GET_HISTORICAL_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
