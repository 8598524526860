import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Card, CardContent, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getTheme } from "@icarius-pages/login/selectors";
import getAnnotationChartData from '@icarius-pages/annotationsPage/components/charts/getAnnotationChartData';
import DoughnutChart from '@icarius-pages/annotationsPage/components/charts/doughnutChart';

const AnnotationsChart = React.memo((props) => {

  const { theme } = useSelector(getTheme);
  const {
    annotationRows,
    getAnnotationSubtypeName,
  } = props;

  const ModifiedCard = withStyles({
    root: {
      height: "calc(25vh - 38px)",
      marginBottom: "5px",
      backgroundColor: theme === "light" && "var(--secondaryBackgroundColor)",
      maxWidth: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })(Card);

  const ModifiedCardContent = withStyles({
    root: {
      width: "100%",
      padding: 4,
      "&:last-child": {
        paddingBottom: 4,
      },
    },
  })(CardContent);

  const annotationData = getAnnotationChartData(annotationRows, getAnnotationSubtypeName);

  return (
    <Grid item xs={12} md={2}>
      <div
        style={{ width: "100%" }}
        className="container relative flex flex-row justify-between items-center">
        <Grid container direction="column">
          {
            annotationData.map((dataItem, index) => (
              <Grid key={index} item xs={12}>
                <ModifiedCard>
                  <ModifiedCardContent>
                    <DoughnutChart data={dataItem} />
                  </ModifiedCardContent>
                </ModifiedCard>
              </Grid>
            ))
          }
        </Grid>
      </div>
    </Grid>
  );
})

export default AnnotationsChart;
