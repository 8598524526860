import { useState } from "react";
import { useSelector } from "react-redux";
import { getAssistanceData, getIsLoadingRegisterAssistance } from "src/app/selectors";

const useDialog = () => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  return [dialogIsOpen, () => setDialogIsOpen(true), () => setDialogIsOpen(false)];
}

const useMyDesktopDialogs = () => {

  const [assistanceDialogIsOpen, handleOpenRegisterAssistanceDialog, handleCloseRegisterAssistanceDialog] = useDialog();
  const [myCertificatesDialogIsOpen, handleOpenMyCertificatesDialog, handleCloseMyCertificatesDialog] = useDialog();
  const [myReceiptsDialogIsOpen, handleOpenMyReceiptsDialog, handleCloseMyReceiptsDialog] = useDialog();
  const [myDigitalDocumentsDialogIsOpen, handleOpenMyDigitalDocumentsDialog, handleCloseMyDigitalDocumentsDialog] = useDialog();
  const [requestDocumentsDialogIsOpen, handleOpenRequestDocumentsDialog, handleCloseRequestDocumentsDialog] = useDialog();
  const [myVacationsDialogIsOpen, handleOpenMyVacationsDialog, handleCloseMyVacationsDialog] = useDialog();
  const [mailDialogIsOpen, handleOpenMailDialog, handleCloseMailDialog] = useDialog();
  const [medalTableDialogIsOpen, handleOpenMedalTableDialog, handleCloseMedalTableDialog] = useDialog();
  const [absentCollaboratorsDialogIsOpen, handleOpenAbsentCollaboratorsDialog, handleCloseAbsentCollaboratorsDialog] = useDialog();

  const assistanceData = useSelector(getAssistanceData);
  const isLoadingAssistance = useSelector(getIsLoadingRegisterAssistance);

  const registerAssistanceIsOpen = assistanceData && assistanceData.can_register_assistance && assistanceDialogIsOpen;

  const myDesktopDialogsData = {
    myCertificatesDialogIsOpen,
    myReceiptsDialogIsOpen,
    myDigitalDocumentsDialogIsOpen,
    requestDocumentsDialogIsOpen,
    myVacationsDialogIsOpen,
    mailDialogIsOpen,
    medalTableDialogIsOpen,
    registerAssistanceIsOpen,
    absentCollaboratorsDialogIsOpen,
    isLoadingAssistance,
    assistanceData,
  }

  const myDesktopDialogsFunctions = {
    handleOpenMyCertificatesDialog,
    handleCloseMyCertificatesDialog,
    handleOpenMyReceiptsDialog,
    handleCloseMyReceiptsDialog,
    handleOpenMyDigitalDocumentsDialog,
    handleCloseMyDigitalDocumentsDialog,
    handleOpenRequestDocumentsDialog,
    handleCloseRequestDocumentsDialog,
    handleOpenMyVacationsDialog,
    handleCloseMyVacationsDialog,
    handleOpenMailDialog,
    handleCloseMailDialog,
    handleOpenRegisterAssistanceDialog,
    handleCloseRegisterAssistanceDialog,
    handleOpenMedalTableDialog,
    handleCloseMedalTableDialog,
    handleOpenAbsentCollaboratorsDialog,
    handleCloseAbsentCollaboratorsDialog,
  }

  return {
    myDesktopDialogsData,
    myDesktopDialogsFunctions,
  }
}

export default useMyDesktopDialogs;