import React from "react";
import CommonPage from "@icarius-common/commonPage";
import HomeHeader from "@icarius-pages/home/components/homeHeader";
import RegisterAssistanceDialog from "@icarius-common/registerAssistanceDialog";
import MyCertificatesAndReceiptsDialog from "@icarius-common/myCertificatesAndReceiptsDialog/components/index";
import { MyDigitalDocumentsDialog } from "@icarius-common/myDigitalDocumentsDialog";
import { Grid, useMediaQuery } from "@material-ui/core";
import RequestDocumentsDialog from "@icarius-common/requestDocumentsDialog";
import MyVacationsDialog from "@icarius-common/myVacationsDialog";
import SendMailDialog from "@icarius-common/mailDialog/sendMailDialog";
import { MedalTable } from "@icarius-common/medalTable";
import useMyDesktop from "../hooks/useMyDesktop";
import Header from "./sections/header/index";
import EasyAccess from "./sections/easyAccess/index";
import Tasks from "./sections/tasks";
import Polls from "./sections/polls";
import Alerts from "./sections/alerts";
import KPI from "./sections/kpi";
import Queries from "./sections/queries";
import Annotations from "./sections/annotations";
import Progress from "./sections/progress";
import Geo from "./sections/geo";
import Consents from "./sections/consents";
import { AbsentCollaboratorsDialog } from "@icarius-common/absentCollaboratorsDialog";

const MyDesktop = () => {

  const {
    myDesktopData,
    myDesktopFunctions,
  } = useMyDesktop();

  const hasPollsAndTasks = Boolean(myDesktopData?.pollsToAnswer?.length) && Boolean(myDesktopData.tasks?.data?.length);
  const isMoreOrEqualsThan960 = useMediaQuery(`(min-width: 960px)`);

  return (
    <CommonPage
      isNotGridPage
      isLoading={myDesktopData.isLoadingDesktop}
    >
      <HomeHeader />
      <div style={{ margin: 10, marginBottom: 30 }}>
        <Header
          handleOpenRegisterAssistanceDialog={myDesktopFunctions.handleOpenRegisterAssistanceDialog}
          handleOpenMyCertificatesDialog={myDesktopFunctions.handleOpenMyCertificatesDialog}
          handleOpenMyReceiptsDialog={myDesktopFunctions.handleOpenMyReceiptsDialog}
          handleOpenMyDigitalDocumentsDialog={myDesktopFunctions.handleOpenMyDigitalDocumentsDialog}
          handleOpenMailDialog={myDesktopFunctions.handleOpenMailDialog}
          handleOpenMedalTableDialog={myDesktopFunctions.handleOpenMedalTableDialog}
          userRole={myDesktopData.role}
          userCode={myDesktopData.code}
          hasMedalTable={myDesktopData.hasMedalTable}
        />
        <div style={{ marginTop: 20 }}>
          <EasyAccess
            handleOpenMyCertificatesDialog={myDesktopFunctions.handleOpenMyCertificatesDialog}
            handleOpenMyReceiptsDialog={myDesktopFunctions.handleOpenMyReceiptsDialog}
            handleOpenMyDigitalDocumentsDialog={myDesktopFunctions.handleOpenMyDigitalDocumentsDialog}
            handleOpenRequestDocumentsDialog={myDesktopFunctions.handleOpenRequestDocumentsDialog}
            handleOpenMyVacationsDialog={myDesktopFunctions.handleOpenMyVacationsDialog}
            handleOpenAbsentCollaboratorsDialog={myDesktopFunctions.handleOpenAbsentCollaboratorsDialog}
            userRole={myDesktopData.role}
          />
        </div>
        <Grid container item xs={12}>
          {
            Boolean(myDesktopData?.pollsToAnswer?.length) &&
            <Grid
              container item sm={12} md={hasPollsAndTasks ? 6 : 12}
              style={{
                marginTop: 20,
                paddingRight: (isMoreOrEqualsThan960 && hasPollsAndTasks) ? 10 : 0
              }}
            >
              <Polls data={myDesktopData.pollsToAnswer} small={hasPollsAndTasks} />
            </Grid>
          }
          {
            Boolean(myDesktopData?.tasks?.data?.length) &&
            <Grid
              container item sm={12} md={hasPollsAndTasks ? 6 : 12}
              style={{
                marginTop: 20,
                paddingLeft: (isMoreOrEqualsThan960 && hasPollsAndTasks) ? 10 : 0
              }}
            >
              <Tasks data={myDesktopData.tasks} small={hasPollsAndTasks} />
            </Grid>
          }
        </Grid>
        {
          myDesktopData.role === "C" &&
          <div style={{ marginTop: 20 }}>
            <Consents
              data={myDesktopData.myConsents}
              isLoading={myDesktopData.isLoadingDesktop}
            />
          </div>
        }
        <div style={{ marginTop: 40 }}>
          <Progress data={myDesktopData.tasks} />
        </div>
        <div style={{ marginTop: 20 }}>
          <Alerts />
        </div>
        <div style={{ marginTop: 20 }}>
          <Annotations userRole={myDesktopData.role} />
        </div>
        <div style={{ marginTop: 40 }}>
          <KPI
            kpiGridDialogData={myDesktopData.kpiGridDialogData}
            favouriteList={myDesktopData.favouriteKPIList}
            handleChangeFavourite={myDesktopFunctions.handleChangeKPIFavourite}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <Queries
            favouriteList={myDesktopData.favouriteQueryList}
            handleChangeFavourite={myDesktopFunctions.handleChangeQueryFavourite}
            userRole={myDesktopData.role}
          />
        </div>
        {
          myDesktopData.role !== "C" &&
          <div style={{ marginTop: 20 }}>
            <Geo
              favouriteList={myDesktopData.favouriteGeoList}
              handleChangeFavourite={myDesktopFunctions.handleChangeQueryFavourite}
              userRole={myDesktopData.role}
            />
          </div>
        }
      </div>
      {
        myDesktopData.registerAssistanceIsOpen &&
        <RegisterAssistanceDialog
          open={myDesktopData.registerAssistanceIsOpen}
          assistanceData={myDesktopData.assistanceData}
          handleClose={myDesktopFunctions.handleCloseRegisterAssistanceDialog}
        />
      }
      {
        myDesktopData.myCertificatesDialogIsOpen &&
        <MyCertificatesAndReceiptsDialog
          open={myDesktopData.myCertificatesDialogIsOpen}
          isCertificate={true}
          handleClose={myDesktopFunctions.handleCloseMyCertificatesDialog}
        />
      }
      {
        myDesktopData.myReceiptsDialogIsOpen &&
        <MyCertificatesAndReceiptsDialog
          open={myDesktopData.myReceiptsDialogIsOpen}
          isCertificate={false}
          handleClose={myDesktopFunctions.handleCloseMyReceiptsDialog}
        />
      }
      {
        myDesktopData.myDigitalDocumentsDialogIsOpen &&
        <MyDigitalDocumentsDialog
          open={myDesktopData.myDigitalDocumentsDialogIsOpen}
          handleCloseDialog={myDesktopFunctions.handleCloseMyDigitalDocumentsDialog}
        />
      }
      {
        myDesktopData.requestDocumentsDialogIsOpen &&
        <RequestDocumentsDialog
          open={myDesktopData.requestDocumentsDialogIsOpen}
          handleClose={myDesktopFunctions.handleCloseRequestDocumentsDialog}
        />
      }
      {
        myDesktopData.absentCollaboratorsDialogIsOpen &&
        <AbsentCollaboratorsDialog
          open={myDesktopData.absentCollaboratorsDialogIsOpen}
          handleClose={myDesktopFunctions.handleCloseAbsentCollaboratorsDialog}
        />
      }
      {
        myDesktopData.myVacationsDialogIsOpen &&
        <MyVacationsDialog
          open={myDesktopData.myVacationsDialogIsOpen}
          handleClose={myDesktopFunctions.handleCloseMyVacationsDialog}
        />
      }
      {
        myDesktopData.mailDialogIsOpen && !myDesktopData.isLoadingDesktop &&
        <SendMailDialog
          open={myDesktopData.mailDialogIsOpen}
          employees={[myDesktopData.manager]}
          handleClose={myDesktopFunctions.handleCloseMailDialog}
          origin={"MyDesktop"}
          customSentToLabel={'Se enviará a mi manager'}
        />
      }
      {
        myDesktopData.medalTableDialogIsOpen &&
        <MedalTable
          dialog
          userCode={myDesktopData.code}
          handleClose={myDesktopFunctions.handleCloseMedalTableDialog}
        />
      }
    </CommonPage>
  )
}

export default MyDesktop;
