import * as actionTypes from "./actionTypes";
import {
  getMyBenefitsAPI,
  requestMyBenefitAPI,
  getMyBenefitsHistoricalAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString } from "@icarius-localization/strings";
import { GET_FULFILLED } from "@icarius-common/medalTable/actionTypes";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "DUPLICATED_DATA":
      errorString = 'Ya existe un plan con el mismo código y nombre';
      break;
    case "ERROR_NO_DATE_AVAILABLE":
      errorString = e.response.data.message;
      break;
    case "VALIDATION_ERROR":
      errorString = getLocalizedErrorString("validationErrorRequests");
      break;
    case "ERROR_DAYS_QUANTITY":
      errorString = getLocalizedErrorString("errorDaysQuantityRequests");
      break;
    case "ERROR_FORMAT_DATES":
      errorString = getLocalizedErrorString("formatDateErrorRequests");
      break;
    case "ERROR_DATES":
      errorString = getLocalizedErrorString("dateErrorRequests");
      break;
    case "ERROR_OVERLAP":
      errorString = getLocalizedErrorString("overlapErrorRequests");
      break;
    case "ERROR_EXISTS":
      errorString = 'Tiene una solicitud con documentación pendiente de aprobación en tramite';
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getMyBenefitsAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getMyBenefitsAPI();

    let data = response.data && response.data.data;
    let consent = response.data && response.data.consent;
    let recipientsList = response.data && response.data.recipientsList;
    let exchangeForMoneyData = response.data && response.data.exchangeForMoneyData;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: {
        data,
        consent,
        recipientsList,
        exchangeForMoneyData,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const requestMyBenefitAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.REQUEST });
  try {
    let response = await requestMyBenefitAPI(dataToSend);

    let data = response.data && response.data.data;
    let consent = response.data && response.data.consent;
    let recipientsList = response.data && response.data.recipientsList;
    let exchangeForMoneyData = response.data && response.data.exchangeForMoneyData;
    let medalTableData = response.data && response.data.medalTableData;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.REQUEST_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.REQUEST_FULFILLED,
      payload: {
        data,
        consent,
        recipientsList,
        exchangeForMoneyData,
      },
    });

    dispatch({
      type: GET_FULFILLED,
      payload: { data: medalTableData },
    });

    dispatch(openSnackbarAction({ msg: 'La solicitud del beneficio se ha procesado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.REQUEST_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const getMyBenefitsHistoricalAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_HISTORICAL });
  try {
    let response = await getMyBenefitsHistoricalAPI();

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_HISTORICAL_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_HISTORICAL_FULFILLED,
      payload: {
        data,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_HISTORICAL_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};