import React from "react";

function PendingCommunicationChannelIcon(props) {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg"  {...props}>
      <g clipPath="url(#clip0_7322_600)">
        <path fillRule="evenodd" clipRule="evenodd" d="M33.9414 14.5784L37.1293 13.7242L37.5317 15.226C37.5442 15.2624 37.5556 15.2996 37.5657 15.3374L43.4071 37.1378L43.4144 37.1359L45.707 45.6922L42.5192 46.5464L42.0975 44.9727C42.0842 44.9346 42.0722 44.8956 42.0616 44.856L41.5076 42.7886L25.6782 41.4371L27.3954 47.8459C27.7588 49.1396 27.589 50.4921 26.917 51.6561C25.5533 54.018 22.5224 54.8302 20.1605 53.4665C18.9965 52.7945 18.1732 51.7081 17.841 50.406L15.9424 43.3204L13.3036 44.0275C10.663 44.7351 7.95342 43.1707 7.24593 40.5301L6.39249 37.345C5.68662 34.7107 7.25555 31.9932 9.88989 31.2873L20.2115 28.5217L34.9285 18.2625L33.9414 14.5784ZM36.2341 23.1348L35.836 21.6493L22.8092 30.7302L24.7706 38.0502L40.6001 39.4017L36.2408 23.133L36.2341 23.1348ZM12.4501 40.8425C11.5699 41.0784 10.6668 40.5569 10.4309 39.6767L9.57748 36.4916C9.34219 35.6135 9.86515 34.7077 10.7433 34.4724L19.7514 32.0587L21.4583 38.4287L12.4501 40.8425ZM24.2195 48.733C24.3421 49.1656 24.286 49.6183 24.0612 50.0076C23.6067 50.7949 22.5963 51.0656 21.809 50.611C21.4198 50.3863 21.1448 50.0224 21.0347 49.5864C21.0327 49.5786 19.1273 42.467 19.1273 42.467L22.3116 41.6138C22.3116 41.6138 24.2173 48.7253 24.2195 48.733Z" fill="var(--svgColor)" />
        <rect x="49.51" y="18.8992" width="3.35995" height="7.13549" transform="rotate(57.914 49.51 18.8992)" fill="var(--svgColor)" />
        <rect x="53.2202" y="29.1237" width="3.35995" height="7.29849" transform="rotate(87.3797 53.2202 29.1237)" fill="var(--svgColor)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12.3109 23.4591C18.5578 23.4591 23.6219 18.395 23.6219 12.1481C23.6219 5.90124 18.5578 0.837158 12.3109 0.837158C6.06408 0.837158 1 5.90124 1 12.1481C1 18.395 6.06408 23.4591 12.3109 23.4591ZM11.1743 16.6725C11.1805 17.2968 11.6867 17.8005 12.3111 17.8036C12.3782 17.8022 12.445 17.7946 12.5107 17.781H12.5158H12.526H12.5344H12.5373C12.9002 17.7024 13.2033 17.4541 13.3517 17.1136C13.4089 16.9736 13.4377 16.8237 13.4365 16.6725H11.1743ZM7.78667 16.1069H16.8354V14.9758L15.7043 14.4103V11.2998C15.7043 9.34186 14.9007 8.07277 13.4421 7.72553V6.49263H11.18V7.72553C10.444 7.88324 9.81079 8.34848 9.44033 9.00366C9.06817 9.7101 8.88797 10.5019 8.91777 11.2998V14.4103L7.78667 14.9758V16.1069Z" fill="var(--svgColor)" />
      </g>
      <defs>
        <clipPath id="clip0_7322_600">
          <rect width="56" height="56" fill="var(--svgColor)" />
        </clipPath>
      </defs>
    </svg>
  );
}



export default PendingCommunicationChannelIcon;
