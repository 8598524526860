import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { useDispatch } from "react-redux";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import Loader from "@icarius-common/loader";

const gridStyle = { height: 75, padding: "0px 10px" };

const RequestBenefitDialog = (props) => {

  const {
    open,
    data,
    recipientsList,
    isLoading,
    handleClose,
    handleSubmit,
  } = props;

  const dispatch = useDispatch();

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    formData,
    setFormValue,
    submit,
  } = useHandleForm(data, handleSubmit, openValidationError);

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {"Solicitar beneficio"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Loader open={isLoading} />
          <Grid container item xs={12}>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label`}>{"Receptor de la solicitud"}</InputLabel>
                <Select
                  value={formData.recipient}
                  labelId={`label`}
                  id={`select`}
                  onChange={(e) => setFormValue(e.target.value, "recipient")}
                  margin={"none"}
                >
                  {
                    recipientsList?.map((item) => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  required
                  fullWidth
                  label={'Fecha de inicio del permiso'}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  invalidDateMessage=''
                  minDate={new Date()}
                  format="dd/MM/yyyy"
                  margin="normal"
                  value={formData.date}
                  onChange={(e) => setFormValue(e, "date")}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
          <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default RequestBenefitDialog;