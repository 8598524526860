import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Typography, Grid } from "@material-ui/core";
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";
import { getAppColor } from "src/app/selectors";
import { ImagePlaceholder } from "@icarius-icons/index";
import ConsentDialog from "@icarius-common/consentDialog";
import {
  acceptMyConsentAction,
  previewMyConsentAction,
} from "@icarius-pages/myDesktop/actions";

const ConsentItem = ({ data, isLoading }) => {

  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const handleItemClick = () => {
    setDialogIsOpen(true);
  }

  const handleCloseDialog = () => {
    setDialogIsOpen(false);
  }

  const color = useSelector(getAppColor);

  if (!data || data.length === 0) return null;

  return (
    <>
      <Grid
        container item xs={12}
        className="myDesktopShadow"
        onClick={handleItemClick}
        style={{
          cursor: 'pointer',
          margin: 10,
          padding: 10,
          backgroundColor: 'var(--secondaryBackgroundColor)',
          borderRadius: 14,
        }}
      >
        <Grid container item xs={12} justify='center' style={{ marginBottom: 10 }}>
          {
            data.image ?
              <img
                style={{ height: 110, borderRadius: 10 }}
                src={IMAGES_ENDPOINT + data.image}
                alt={"imageNotFound"}
              />
              :
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 220,
                  height: 110,
                  backgroundColor: color,
                  borderRadius: 10
                }}
              >
                <ImagePlaceholder fontSize="small" style={{ fill: "var(--icons)" }} />
              </div>
          }
        </Grid>
        <Grid container item xs={12} justify='center'>
          <Typography className="whiteText" style={{ textAlign: 'center', fontSize: 20, fontWeight: 400 }}>
            {data.name}
          </Typography>
        </Grid>
      </Grid>
      {
        dialogIsOpen &&
        <ConsentDialog
          open={dialogIsOpen}
          data={data}
          isLoading={isLoading}
          handleClose={handleCloseDialog}
          acceptAction={acceptMyConsentAction}
          previewAction={previewMyConsentAction}
        />
      }
    </>
  )
}

export default ConsentItem;