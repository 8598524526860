import React from "react";
import { getColorByPercentage } from "./avatarWithProgress";

const ProgressBar = ({ value, small }) => {
  return (
    <div
      style={{
        minWidth: small ? 48 : 300,
        maxWidth: small ? 150 : '',
        height: small ? 8 : 16,
        position: 'relative',
        margin: '0 auto',
        marginBottom: 5,
        marginTop: 5,
      }}
    >
      <div
        style={{
          borderRadius: 15,
          height: small ? 8 : 16,
          width: `${value}%`,
          backgroundColor: getColorByPercentage(value),
          position: 'absolute',
          zIndex: 2
        }}
      />
      <div
        style={{
          borderRadius: 15,
          height: small ? 8 : 16,
          width: '100%',
          backgroundColor: 'var(--genreBackground)',
          position: 'absolute'
        }}
      />
    </div>
  )
}

export default ProgressBar;