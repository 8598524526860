import {
  GET_ASSISTANCE_ROWS,
  GET_ASSISTANCE_ROWS_FULFILLED,
  GET_ASSISTANCE_ROWS_REJECTED,
} from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getMyAssistance } from "@icarius-connection/api";

export const getAssistanceTableRows = () => async (dispatch) => {
  dispatch({ type: GET_ASSISTANCE_ROWS });
  try {
    let response = await getMyAssistance();
    let assistance = response.data && response.data.data;

    dispatch({
      type: GET_ASSISTANCE_ROWS_FULFILLED,
      payload: {
        assistanceRows: assistance,
        dateFormat: response.data.date_format,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: GET_ASSISTANCE_ROWS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};
