import NAME from "./constants";

export const getIsLoading = store => store[NAME].isLoading;
export const getHasLoans = store => store[NAME].hasLoans;
export const getHasMedalTable = store => store[NAME].hasMedalTable;
export const getMostVisitedPages = store => store[NAME].mostVisitedPages;
export const getTasks = store => store[NAME].tasks;
export const getAlerts = store => store[NAME].alerts;
export const getAnnotations = store => store[NAME].annotations;
export const getQueries = store => store[NAME].queries;
export const getManager = store => store[NAME].manager;
export const getLikes = store => store[NAME].likes;
export const getMyRequestsAmount = store => store[NAME].myRequestsAmount;
export const getMyPendingAmount = store => store[NAME].myPendingAmount;
export const getRequestedDocumentsAmount = store => store[NAME].requestedDocumentsAmount;
export const getExtraHours = store => store[NAME].extraHours;
export const getMyActionsAmount = store => store[NAME].myActionsAmount;
export const getFeedbackAmount = store => store[NAME].feedbackAmount;
export const getCommunicationsAmount = store => store[NAME].communicationsAmount;
export const getPendingCommunicationsAmount = store => store[NAME].pendingCommunicationsAmount;
export const getHoursChangedAmount = store => store[NAME].hoursChangedAmount;
export const getHoursChangedMinDate = store => store[NAME].hoursChangedMinDate;
export const getProgress = store => store[NAME].progress;
export const getGeo = store => store[NAME].geo;
export const getMyConsents = store => store[NAME].myConsents;
export const getOnboardingTrackingAmount = store => store[NAME].onboardingTrackingAmount;