import React from "react";

function MyActionsIcon(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_6027_640)">
        <path d="M9.99988 24C7.93298 24 6.24988 22.318 6.24988 20.25C6.24988 19.836 6.58588 19.5 6.99988 19.5C7.41388 19.5 7.74988 19.836 7.74988 20.25C7.74988 21.4911 8.75989 22.5 9.99988 22.5C11.2399 22.5 12.2499 21.4911 12.2499 20.25C12.2499 19.836 12.5859 19.5 12.9999 19.5C13.4139 19.5 13.7499 19.836 13.7499 20.25C13.7499 22.318 12.067 24 9.99988 24Z" fill="var(--svgColor)" />
        <path d="M18.2499 21H1.74994C0.784973 21 0 20.2151 0 19.2501C0 18.738 0.223022 18.2531 0.611939 17.92C0.637024 17.8981 0.66394 17.8781 0.691956 17.86C2.15991 16.579 3 14.736 3 12.79V9.99999C3 6.74309 5.20697 3.94707 8.367 3.2C8.77295 3.10698 9.17505 3.35399 9.27008 3.75811C9.36493 4.16112 9.11499 4.56505 8.71307 4.66008C6.23199 5.24602 4.5 7.442 4.5 9.99999V12.79C4.5 15.206 3.44092 17.4921 1.59705 19.061C1.58203 19.073 1.56903 19.084 1.55292 19.095C1.526 19.1291 1.5 19.1811 1.5 19.2501C1.5 19.386 1.61407 19.5 1.74994 19.5H18.2499C18.386 19.5 18.5001 19.386 18.5001 19.2501C18.5001 19.18 18.4741 19.1291 18.446 19.095C18.431 19.084 18.4169 19.073 18.403 19.061C17.5071 18.297 16.7999 17.3751 16.3021 16.3201C16.1241 15.946 16.2839 15.499 16.6589 15.321C17.037 15.1431 17.48 15.3051 17.657 15.679C18.0529 16.5151 18.6079 17.2491 19.3099 17.8631C19.3359 17.881 19.363 17.9001 19.3859 17.92C19.777 18.2531 20.0001 18.738 20.0001 19.2501C20.0001 20.2151 19.2151 21 18.2499 21Z" fill="var(--svgColor)" />
        <path d="M17.4858 13.0283C13.8882 13.0283 10.9717 10.1118 10.9717 6.51416C10.9717 2.91649 13.8882 0 17.4858 0C21.0835 0 24 2.91649 24 6.51416C23.9961 10.1102 21.0819 13.0244 17.4858 13.0283ZM17.4858 1.30283C14.6077 1.30283 12.2745 3.63602 12.2745 6.51416C12.2745 9.3923 14.6077 11.7255 17.4858 11.7255C20.364 11.7255 22.6972 9.3923 22.6972 6.51416C22.6939 3.63736 20.3626 1.30606 17.4858 1.30283ZM20.7429 7.16558L16.8344 7.16558V3.25708L18.1373 3.25708V5.86274L20.7429 5.86274V7.16558Z" fill="var(--svgColor)" />
      </g>
      <defs>
        <clipPath id="clip0_6027_640">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default MyActionsIcon;
