import React from "react";

const MyDigitalFolderIcon = (props) => (
  <svg {...props} className={"icon"} width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.2005 16.3333V9.33333C14.2005 8.71449 14.4464 8.121 14.8839 7.68342C15.3215 7.24583 15.915 7 16.5339 7H31.4999L36.1665 11.6667H49.2005C49.8194 11.6667 50.4129 11.9125 50.8504 12.3501C51.288 12.7877 51.5339 13.3812 51.5339 14V37.3333C51.5339 37.9522 51.288 38.5457 50.8504 38.9832C50.4129 39.4208 49.8194 39.6667 49.2005 39.6667H42.2005V46.6667C42.2005 47.2855 41.9547 47.879 41.5171 48.3166C41.0795 48.7542 40.486 49 39.8672 49H7.20052C6.58168 49 5.98819 48.7542 5.5506 48.3166C5.11302 47.879 4.86719 47.2855 4.86719 46.6667V18.6667C4.86719 18.0478 5.11302 17.4543 5.5506 17.0168C5.98819 16.5792 6.58168 16.3333 7.20052 16.3333H14.2005ZM14.2005 21H9.53385V44.3333H37.5339V39.6667H14.2005V21Z"
      fill="var(--svgColor)"
    />
  </svg>
);

export default MyDigitalFolderIcon;
