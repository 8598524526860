import React from "react";
import { Grid } from "@material-ui/core";
import ContainerTitle from "../../containerTitle";
import ConsentItem from "./consentItem";

const Consents = ({ isLoading, data }) => {

  if (!data || data.length === 0) return null;

  return (
    <Grid container direction="column">
      <ContainerTitle title='Mis consentimientos pendientes' />
      <Grid container>
        {
          data.map((item) => {
            return (
              <Grid key={item.code} container item sm={12} md={6} lg={4}>
                <ConsentItem
                  data={item}
                  isLoading={isLoading}
                />
              </Grid>
            )
          })
        }
      </Grid>
    </Grid>
  )
}

export default Consents;