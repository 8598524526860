import React, { useMemo } from "react";
import { Grid, Tooltip, Typography } from "@material-ui/core";
import paths from "@icarius-localization/paths";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getExtraHours,
  getMyActionsAmount,
  getFeedbackAmount,
  getHasLoans,
  getHoursChangedAmount,
  getMostVisitedPages,
  getCommunicationsAmount,
  getHoursChangedMinDate,
  getPendingCommunicationsAmount,
  getOnboardingTrackingAmount,
} from "@icarius-pages/myDesktop/selectors";
import { getAvailableMenus } from "src/app/selectors";
import MyDigitalFolderIcon from "./myDigitalFolderIcon";
import MyLoansIcon from "./myLoansIcon";
import MyScheduleIcon from "./myScheduleIcon";
import AnnotationAnalysisIcon from "./annotationAnalysisIcon";
import MyBenefitsIcon from "./myBenefitsIcon";
import MyVacationsIcon from "./myVacationsIcon";
import AbsentCollaboratorsIcon from "./absentCollaboratorsIcon";
import OnboardingTrackingIcon from "./onboardingTrackingIcon";
import ScheduleChangeRequestsIcon from "./scheduleChangeRequestsIcon";
import MyActionsIcon from "./myActionsIcon";
import ExtraHoursAuthIcon from "./extraHoursAuthIcon";
import FeedbackIcon from "./feedbackIcon";
import CommunicationChannelIcon from "./communicationChannelIcon";
import PendingCommunicationChannelIcon from "./pendingCommunicationChannelIcon";

const EasyAccess = (props) => {

  const {
    handleOpenMyCertificatesDialog,
    handleOpenMyReceiptsDialog,
    handleOpenMyDigitalDocumentsDialog,
    handleOpenRequestDocumentsDialog,
    handleOpenMyVacationsDialog,
    handleOpenAbsentCollaboratorsDialog,
    userRole,
  } = props;

  const history = useHistory();
  const hasLoans = useSelector(getHasLoans);
  const mostVisitedPages = useSelector(getMostVisitedPages);
  const availableMenus = useSelector(getAvailableMenus);
  const extraHours = useSelector(getExtraHours);
  const myActionsAmount = useSelector(getMyActionsAmount);
  const feedbackAmount = useSelector(getFeedbackAmount);
  const communicationsAmount = useSelector(getCommunicationsAmount);
  const pendingCommunicationsAmount = useSelector(getPendingCommunicationsAmount);
  const hoursChangedAmount = useSelector(getHoursChangedAmount);
  const hoursChangedMinDate = useSelector(getHoursChangedMinDate);
  const onboardingTrackingAmount = useSelector(getOnboardingTrackingAmount);

  const dataByRole = useMemo(() => {
    return {
      C: [
        {
          name: 'Mis vacaciones',
          icon: <MyVacationsIcon />,
          path: paths.myVacations,
        },
        {
          name: 'Mi carpeta digital',
          path: paths.myDigitalFolder,
          icon: <MyDigitalFolderIcon />,
        },
        {
          name: 'Mis horarios programados',
          icon: <MyScheduleIcon />,
          path: paths.mySchedule,
        },
        {
          name: 'Mis préstamos',
          icon: <MyLoansIcon />,
          path: paths.loans,
        },
        {
          name: 'Mis beneficios',
          icon: <MyBenefitsIcon />,
          path: paths.myBenefits,
        },
        {
          name: 'Mis acciones y recordatorios',
          icon: <MyActionsIcon style={{ width: 56, height: 56, fill: 'var(--svgColor)' }} />,
          path: paths.actions,
          amount: myActionsAmount,
        },
        {
          name: 'Mis feedbacks',
          icon: <FeedbackIcon />,
          path: paths.feedback,
          amount: feedbackAmount,
        },
        {
          name: 'Mi canal de comunicaciones',
          icon: <CommunicationChannelIcon />,
          path: paths.communicationChannel,
          amount: communicationsAmount,
          onClick: () => {
            history.push({
              pathname: paths.communicationChannel,
              state: { pendingCommunications: false },
            });
          }
        },
        {
          name: 'Mis procesos de onboarding',
          icon: <OnboardingTrackingIcon />,
          path: paths.myOnboardingTracking,
          amount: onboardingTrackingAmount,
        },
      ],
      M: [
        {
          name: 'Vacaciones de mi personal',
          icon: <MyVacationsIcon />,
          path: paths.vacations,
        },
        {
          name: 'Anotaciones de mi personal',
          path: paths.annotationsAnalysis,
          icon: <AnnotationAnalysisIcon style={{ width: 56, height: 56, fill: 'var(--svgColor)' }} />,
        },
        {
          name: 'Horarios de mi personal',
          icon: <MyScheduleIcon />,
          path: paths.myPeopleSchedule,
          onClick: () => {
            const stateToSend = { from: paths.myDesktop };

            history.push({
              pathname: paths.myPeopleSchedule,
              state: stateToSend,
            });
          }
        },
        {
          name: 'Autorización de horas extra',
          icon: <ExtraHoursAuthIcon />,
          path: paths.extraHoursAuthorization,
          amount: extraHours,
        },
        {
          name: 'Solicitudes de cambios de horarios',
          path: paths.myPeopleSchedule,
          amount: hoursChangedAmount,
          icon: <ScheduleChangeRequestsIcon style={{ width: 56, height: 56, fill: 'var(--svgColor)' }} />,
          onClick: () => {
            const stateToSend = { setRequestFilter: true, from: paths.myDesktop, date: hoursChangedMinDate };

            history.push({
              pathname: paths.myPeopleSchedule,
              state: stateToSend,
            });
          }
        },
        {
          name: 'Mis acciones y recordatorios',
          icon: <MyActionsIcon style={{ width: 56, height: 56, fill: 'var(--svgColor)' }} />,
          path: paths.actions,
          amount: myActionsAmount,
        },
        {
          name: 'Mis feedbacks',
          icon: <FeedbackIcon />,
          path: paths.feedback,
          amount: feedbackAmount,
        },
        {
          name: 'Mis comunicaciones creadas',
          icon: <CommunicationChannelIcon />,
          path: paths.communicationChannel,
          amount: communicationsAmount,
          onClick: () => {
            history.push({
              pathname: paths.communicationChannel,
              state: { pendingCommunications: false },
            });
          }
        },
        {
          name: 'Comunicaciones que debo atender',
          icon: <PendingCommunicationChannelIcon />,
          path: paths.communicationChannel,
          amount: pendingCommunicationsAmount,
          onClick: () => {
            history.push({
              pathname: paths.communicationChannel,
              state: { pendingCommunications: true },
            });
          }
        },
        {
          name: 'Mis tareas de onboarding',
          icon: <OnboardingTrackingIcon />,
          path: paths.onboardingTracking,
          amount: onboardingTrackingAmount,
        },
        {
          name: 'Colaboradores ausentes',
          icon: <AbsentCollaboratorsIcon />,
          onClick: handleOpenAbsentCollaboratorsDialog,
        },
      ],
      E: [
        {
          name: 'Mis acciones y recordatorios',
          icon: <MyActionsIcon style={{ width: 56, height: 56, fill: 'var(--svgColor)' }} />,
          path: paths.actions,
          amount: myActionsAmount,
        },
        {
          name: 'Mis feedbacks',
          icon: <FeedbackIcon />,
          path: paths.feedback,
          amount: feedbackAmount,
        },
        {
          name: 'Canal de comunicaciones',
          icon: <CommunicationChannelIcon />,
          path: paths.communicationChannel,
          amount: communicationsAmount,
          onClick: () => {
            history.push({
              pathname: paths.communicationChannel,
              state: { pendingCommunications: false },
            });
          }
        },
        {
          name: 'Colaboradores ausentes',
          icon: <AbsentCollaboratorsIcon />,
          onClick: handleOpenAbsentCollaboratorsDialog,
        },
      ],
    }
  },
    [
      extraHours,
      myActionsAmount,
      feedbackAmount,
      communicationsAmount,
      pendingCommunicationsAmount,
      hoursChangedAmount,
      hoursChangedMinDate,
      history,
      onboardingTrackingAmount,
      handleOpenAbsentCollaboratorsDialog,
    ]
  )

  const dataToUse = useMemo(() => {
    let auxData = [
      ...dataByRole[userRole],
      ...mostVisitedPages,
    ];

    if (!hasLoans && userRole === "C") {
      auxData = auxData.filter(item => item.path !== paths.loans);
    }

    // saco los que sean de menus que no puedo ver
    auxData = auxData.filter((item) => {
      if (!item.path) return true;
      return availableMenus.includes(item.path.replace("/", ""))
    })

    if (pendingCommunicationsAmount === 0) {
      auxData = auxData.filter(item => item.name !== 'Comunicaciones que debo atender');
    }

    return auxData.slice(0, 18);
  },
    [
      dataByRole,
      hasLoans,
      mostVisitedPages,
      availableMenus,
      userRole,
      pendingCommunicationsAmount,
    ]
  )

  const handleOnClick = (item) => {
    if (item?.onClick) {
      item.onClick();
      return;
    }

    switch (item.path) {
      case paths.myCertificates: {
        handleOpenMyCertificatesDialog();
        break;
      }
      case paths.myReceipts: {
        handleOpenMyReceiptsDialog();
        break;
      }
      case paths.digitalDocuments: {
        handleOpenMyDigitalDocumentsDialog();
        break;
      }
      case paths.requestDocuments: {
        handleOpenRequestDocumentsDialog();
        break;
      }
      case paths.myVacations: {
        handleOpenMyVacationsDialog();
        break;
      }
      default: {
        history.push(item.path);
        break;
      }
    }
  }

  return (
    <Grid container>
      {
        dataToUse.map((item, index) => {
          const hasCounter = typeof item?.amount === 'number' && item?.amount !== 0;

          return (
            <Grid key={index} item xs={12} sm={6} md={4} lg={2} style={{ padding: 10 }}>
              <Tooltip title={item.name}>
                <Grid
                  onClick={() => handleOnClick(item)}
                  container item alignItems="center" justify="center"
                  className="myDesktopShadow easyAccessItem"
                  style={{
                    background: 'var(--secondaryBackgroundColor)',
                    borderRadius: 7,
                    height: 84,
                    cursor: 'pointer',
                    position: 'relative',
                  }}
                >
                  {
                    hasCounter &&
                    <div className="easyAccessCounter">
                      <Typography style={{ color: 'var(--secondaryBackgroundColor)', fontSize: 12, fontWeight: 500 }}>
                        {item.amount > 9 ? '+9' : item.amount}
                      </Typography>
                    </div>
                  }
                  {item.icon}
                  {
                    !item.icon &&
                    <Typography className="whiteText" align="center" style={{ padding: hasCounter ? "10px 36px" : 10 }}>
                      {item.name}
                    </Typography>
                  }
                </Grid>
              </Tooltip>
            </Grid>
          )
        })
      }
    </Grid>
  )
}

export default EasyAccess;