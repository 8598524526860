import React from "react";
import { Typography } from "@material-ui/core";
import ProgressBar from "./progressBar";
import ProfileImg from "@icarius-common/profileImg";

export const getColorByPercentage = (percentage) => {
  let colorToUse = "#FF4040";
  if (percentage > 40 && percentage <= 70) {
    colorToUse = "#F5A506";
  } else if (percentage > 70) {
    colorToUse = "#00993D";
  }
  return colorToUse;
}

const AvatarWithProgress = ({ percentage, name, avatar }) => {
  return (
    <div style={{ position: 'relative', margin: 10 }}>
      <div style={{ height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <ProfileImg
          image={avatar}
          size={100}
        />
      </div>
      <Typography className="whiteText" align='center' style={{ fontWeight: 500 }}>
        {percentage}%
      </Typography>
      <ProgressBar value={percentage} small />
      <Typography className="whiteText" align='center' style={{ fontWeight: 400 }}>
        {name}
      </Typography>
    </div>
  )
}

export default AvatarWithProgress;