import * as actionTypes from "./actionTypes";

const initialState = {
  gettingMyAnnotations: false,
  myAnnotationRows: [],
  categories: [],
  types: [],
  subtypes: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_MY_ANNOTATION_ROWS:
      return { ...state, gettingMyAnnotations: true };
    case actionTypes.GET_MY_ANNOTATION_ROWS_FULFILLED:
      return {
        ...state,
        gettingMyAnnotations: false,
        myAnnotationRows: action.payload.rows,
        categories: action.payload.categories,
        types: action.payload.types,
        subtypes: action.payload.subtypes,
      };
    case actionTypes.GET_MY_ANNOTATION_ROWS_REJECTED:
      return { ...state, gettingMyAnnotations: false };
    default:
      return state;
  }
}
