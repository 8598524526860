
import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";

export default [
    {
        pages: [paths.myAssistance],
        config: {
            headerName: "Lugar de asistencia de entrada",
            field: "LUGAR DE ASISTENCIA ENTRADA",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
        }
    },
    {
        pages: [paths.myAssistance],
        config: {
            headerName: 'Referencia de entrada',
            field: 'REFERENCIA ENTRADA',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.myAssistance],
        config: {
            headerName: "Origen de entrada",
            field: "ORIGEN ENTRADA",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.myAssistance],
        config: {
            headerName: getLocalizedString("inDate"),
            field: "FECHA DE ENTRADA",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: [paths.myAssistance],
        config: {
            headerName: getLocalizedString("inHour"),
            field: "HORA DE ENTRADA",
            filter: "agTextColumnFilter",
            valueGetter: function (params) {
                return (params.data && (params.data["HORA DE ENTRADA"] || "")) || "";
            },
        }
    },
    {
        pages: [paths.myAssistance],
        config: {
            headerName: "Lugar de asistencia de salida",
            field: "LUGAR DE ASISTENCIA SALIDA",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.myAssistance],
        config: {
            headerName: 'Referencia de salida',
            field: 'REFERENCIA SALIDA',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.myAssistance],
        config: {
            headerName: "Origen de salida",
            field: "ORIGEN SALIDA",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.myAssistance],
        config: {
            headerName: "Fecha de salida",
            field: "FECHA DE SALIDA",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: [paths.myAssistance],
        config: {
            headerName: "Hora de salida",
            field: "HORA DE SALIDA",
            filter: "agTextColumnFilter",
            valueGetter: function (params) {
                return (params.data && (params.data["HORA DE SALIDA"] || "")) || "";
            },
        }
    },
    {
        pages: [paths.myAssistance],
        config: {
            headerName: "Tiempo transcurrido entre marcas",
            field: "TIEMPO TRANSCURRIDO ENTRE MARCAS",
            cellRenderer: "TimeBetweenMarksRenderer",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.myAssistance],
        config: {
            headerName: getLocalizedString("cant"),
            field: "CANTIDAD",
        }
    },
    {
        pages: [paths.myAssistance],
        config: {
            headerName: getLocalizedString("outOfZoneIN"),
            field: "FUERA DE ZONA (ENTRADA)",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.myAssistance],
        config: {
            headerName: getLocalizedString("outOfZoneOUT"),
            field: "FUERA DE ZONA (SALIDA)",
            filter: "agSetColumnFilter",
        }
    }, {
        pages: [paths.myAssistance],
        config: {
            headerName: "Comentarios",
            field: "COMENTARIOS",
            filter: "agTextColumnFilter",
        }
    },
]