import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import paths from "@icarius-localization/paths";
import { useSelector } from "react-redux";
import { getAvailableMenus, getDigitalDocumentsAmount } from "src/app/selectors";
import { getMyPendingAmount, getMyRequestsAmount, getRequestedDocumentsAmount } from "@icarius-pages/myDesktop/selectors";

const CustomButton = ({ title, onClick, amount }) => {

  return (
    <Button
      onClick={onClick}
      fullWidth
      style={{ background: 'transparent', border: '2px solid var(--icons)' }}
      className="whiteText"
    >
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography className="whiteText" style={{ fontSize: 14, paddingRight: amount ? 10 : 0 }}>
          {title}
        </Typography>
        {
          amount > 0 &&
          <div style={{ height: 22, width: 22, borderRadius: '50%', backgroundColor: 'var(--icons)' }}>
            <Typography style={{ fontSize: 14, fontWeight: 500, color: 'var(--secondaryBackgroundColor)' }}>
              {amount > 9 ? '+9' : amount}
            </Typography>
          </div>
        }
      </div>
    </Button>
  )
}

const ThirdSection = (props) => {

  const {
    handleOpenMyCertificatesDialog,
    handleOpenMyReceiptsDialog,
    handleOpenMyDigitalDocumentsDialog,
    userRole,
  } = props;

  const history = useHistory();
  const digitalDocumentsQuantity = useSelector(getDigitalDocumentsAmount);
  const availableMenus = useSelector(getAvailableMenus);
  const myRequestsAmount = useSelector(getMyRequestsAmount);
  const myPendingAmount = useSelector(getMyPendingAmount);
  const requestedDocumentsAmount = useSelector(getRequestedDocumentsAmount);

  const dataByRole = {
    C: [
      {
        name: 'Mis certificados',
        onClick: handleOpenMyCertificatesDialog,
        path: paths.myCertificates,
      },
      {
        name: 'Mis solicitudes',
        onClick: () => history.push(paths.myRequests),
        amount: myRequestsAmount,
        path: paths.myRequests,
      },
      {
        name: 'Documentos digitales',
        onClick: handleOpenMyDigitalDocumentsDialog,
        amount: digitalDocumentsQuantity,
        path: paths.digitalDocuments,
      },
      {
        name: 'Mis recibos',
        onClick: handleOpenMyReceiptsDialog,
        path: paths.myReceipts,
      },
    ],
    M: [
      {
        name: 'Mis pendientes',
        onClick: () => history.push(paths.myPending),
        amount: myPendingAmount,
        path: paths.myPending,
      },
      {
        name: 'Mis solicitudes',
        onClick: () => history.push(paths.myRequestsManager),
        amount: myRequestsAmount,
        path: paths.myRequestsManager,
      },
      {
        name: 'Documentos digitales',
        onClick: handleOpenMyDigitalDocumentsDialog,
        amount: digitalDocumentsQuantity,
        path: paths.digitalDocuments,
      },
      {
        name: 'Mi personal',
        onClick: () => history.push(paths.myPeople),
        path: paths.myPeople,
      },
    ],
    E: [
      {
        name: 'Mis pendientes',
        onClick: () => history.push(paths.myPendingEmployer),
        amount: myPendingAmount,
        path: paths.myPendingEmployer,
      },
      {
        name: 'Documentos solicitados',
        onClick: () => history.push(paths.requestedDocuments),
        amount: requestedDocumentsAmount,
        path: paths.requestedDocuments,
      },
      {
        name: 'Maestro de personas',
        onClick: () => history.push(paths.employeeMaster),
        path: paths.employeeMaster,
      },
      {
        name: 'Mi personal',
        onClick: () => history.push(paths.myPeople),
        path: paths.myPeople,
      },
    ]
  }

  const filteredData = dataByRole[userRole]?.filter(item => availableMenus.includes(item.path.replace("/", "")))

  return (
    <Grid container style={{ paddingTop: 20 }}>
      {
        filteredData.map(item => {
          return (
            <Grid key={item.name} item xs={12} sm={6} md={3} style={{ padding: 5 }}>
              <CustomButton
                title={item.name}
                onClick={item.onClick}
                amount={item.amount}
              />
            </Grid>
          )
        })
      }
    </Grid>
  )
}

export default ThirdSection;