import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMyBenefitsAction, getMyBenefitsHistoricalAction, requestMyBenefitAction,
} from "../actions";
import {
  getConsent,
  getData,
  getExchangeForMoneyData,
  getHistoricalData,
  getLoading,
  getRecipientsList,
} from "../selectors";
import { getUserData } from "src/app/selectors";
import { RESET_STATE } from "../actionTypes";
import { openDialogAction } from "@icarius-common/dialog/actions";

const useMyBenefits = () => {

  const [selectedBenefit, setSelectedBenefit] = useState(null);
  const [requestDialogIsOpen, setRequestDialogIsOpen] = useState(false);
  const [historicalDialogIsOpen, setHistoricalDialogIsOpen] = useState(false);

  const dispatch = useDispatch();
  const data = useSelector(getData);
  const historicalData = useSelector(getHistoricalData);
  const consent = useSelector(getConsent);
  const recipientsList = useSelector(getRecipientsList);
  const exchangeForMoneyData = useSelector(getExchangeForMoneyData);
  const isLoading = useSelector(getLoading);
  const { code } = useSelector(getUserData);

  useEffect(() => {
    dispatch(getMyBenefitsAction());

    return () => {
      dispatch({ type: RESET_STATE });
    }
  }, [dispatch])

  const handleRequest = (benefit) => {
    dispatch(openDialogAction({
      title: 'Atención',
      msg: `¿Desea solicitar el beneficio ${benefit?.name || ''}?`,
      onConfirm: () => {
        if (!benefit) { // es exchangeForMoney
          submitRequest({
            exchangeForMoney: true,
          });
          return;
        }

        if (benefit.requiresPermission) { // no es exchangeForMoney pero necesita pedir mas datos
          setSelectedBenefit(benefit);
          setRequestDialogIsOpen(true);
          return;
        }

        // no es exchangeForMoney y no necesita pedir mas datos
        submitRequest({
          code: benefit.code,
        });
      }
    }))
  }

  const submitRequest = (dataToSend) => {
    dispatch(requestMyBenefitAction(dataToSend))
      .then((res) => res?.status === 200 && handleCloseRequestDialog());
  }

  const handleCloseRequestDialog = () => {
    setRequestDialogIsOpen(false);
    setSelectedBenefit(null);
  }

  const handleOpenHistoricalDialog = () => {
    dispatch(getMyBenefitsHistoricalAction())
      .then((res) => {
        if (res?.status === 200) {
          setHistoricalDialogIsOpen(true);
        }
      });
  }

  const handleCloseHistoricalDialog = () => {
    setHistoricalDialogIsOpen(false);
  }

  const state = {
    selectedBenefit,
    requestDialogIsOpen,
    historicalDialogIsOpen,
  };

  const handlers = {
    handleRequest,
    handleCloseRequestDialog,
    handleOpenHistoricalDialog,
    handleCloseHistoricalDialog,
    submitRequest,
  }

  return {
    userCode: code,
    data,
    historicalData,
    consent,
    recipientsList,
    exchangeForMoneyData,
    isLoading,
    state,
    handlers,
  }
}

export default useMyBenefits;