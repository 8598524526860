import React from "react";
import Container from "../../container";
import { useSelector } from "react-redux";
import { getProgress } from "@icarius-pages/myDesktop/selectors";
import ProgressItem from "./progressItem";
import ProgressBar from "./progressBar";
import { Grid, Typography } from "@material-ui/core";

const Progress = () => {
  const progress = useSelector(getProgress);

  if (!progress?.length) return null;

  const totalProgress = (progress.reduce((total, item) => total + item.progress, 0) / (progress.length || 1)).toFixed(2);

  const progressBarItem = (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <Typography className="whiteText" style={{ fontSize: 25, fontWeight: 500, marginRight: 5 }}>
          {totalProgress}%
        </Typography>
        <Typography className="whiteText" style={{ fontSize: 18 }}>
          {'progreso total'}
        </Typography>
      </div>
      <ProgressBar value={totalProgress} />
    </div>
  )

  return (
    <Container title='Progreso de colaboradores' customItem={progressBarItem}>
      <Grid container>
        {
          progress.map((item, index) => {
            return (
              <Grid key={index} container item sm={12} md={6} style={{ marginBottom: 30 }}>
                <ProgressItem
                  name={item.name}
                  percentage={item.progress}
                  avatar={item.avatar}
                  data={item.data}
                  small
                />
              </Grid>
            )
          })
        }
      </Grid>
    </Container>
  )
}

export default Progress;