import React from "react";

function FeedbackIcon(props) {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_6333_11308)">
        <path d="M56 5.3171C56 2.38588 53.6819 0 50.8093 0H5.19073C2.31808 0 0 2.37452 0 5.3171V56L18.223 44.0138H50.8093C53.6708 44.0138 56 41.6393 56 38.6967V5.3171ZM52.1402 40.0601H16.0491C14.9289 40.0601 13.8419 40.435 12.9436 41.1167L3.85977 48.0925V3.95374H52.1513V40.0601H52.1402Z" fill="var(--svgColor)" />
        <path d="M17.3357 18.4735H20.4191V31.1073H17.3357C17.0584 31.1073 16.8033 30.9937 16.6037 30.8006C16.4151 30.6074 16.3042 30.3348 16.3042 30.0507V19.5302C16.3042 19.2461 16.4151 18.9848 16.6037 18.7803C16.7922 18.5872 17.0584 18.4735 17.3357 18.4735ZM22.7704 17.1102L29.3476 10.3729C29.4363 10.282 29.5472 10.2252 29.6692 10.2252C29.7912 10.2252 29.9132 10.2479 30.0131 10.3275L30.8893 10.9978C31.1333 11.1796 31.3218 11.4409 31.4216 11.7363C31.5215 12.0317 31.5326 12.3498 31.466 12.6452L30.2792 17.4169H36.8564C37.3999 17.4169 37.9212 17.6442 38.3093 18.0305C38.6975 18.4167 38.9083 18.9621 38.9083 19.5188V21.7342C38.9083 22.0069 38.8528 22.2796 38.753 22.5409L35.5698 30.4484C35.4922 30.6415 35.3591 30.8006 35.1927 30.9256C35.0263 31.0392 34.8267 31.1073 34.6159 31.1073H23.4914C23.2141 31.1073 22.959 30.9937 22.7593 30.8006C22.5708 30.6074 22.4599 30.3348 22.4599 30.0507V17.86C22.4599 17.576 22.5708 17.3147 22.7593 17.1215L22.7704 17.1102Z" fill="var(--svgColor)" />
      </g>
      <defs>
        <clipPath id="clip0_6333_11308">
          <rect width="56" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default FeedbackIcon;
