import React from "react";

const MyLoansIcon = (props) => (
  <svg {...props} className={"icon"} width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32.665 4.66675C36.9349 4.66471 41.0765 6.12664 44.3989 8.80871C47.7214 11.4908 50.024 15.2309 50.9225 19.4052C51.8211 23.5795 51.2614 27.9357
        49.3367 31.7473C47.4121 35.5589 44.2389 38.5954 40.3463 40.3504C39.1021 43.1012 37.2092 45.5092 34.83 47.3677C32.4508 49.2263 29.6563 50.4801 
        26.6861 51.0215C23.7159 51.563 20.6588 51.3759 17.7768 50.4763C14.8948 49.5768 12.274 47.9916 10.1392 45.8568C8.00439 43.722 6.41923 41.1012 5.51969
        38.2192C4.62015 35.3373 4.43307 32.2801 4.97449 29.3099C5.51592 26.3398 6.76969 23.5453 8.62829 21.1661C10.4869 18.7869 12.8948 16.894 15.6456
        15.6497C17.1253 12.3758 19.518 9.59825 22.5368 7.65017C25.5555 5.70208 29.0722 4.66619 32.665 4.66675ZM23.3316 18.6667C21.4931 18.6667 19.6726 
        19.0289 17.9741 19.7324C16.2755 20.436 14.7321 21.4672 13.4321 22.7673C12.1321 24.0673 11.1009 25.6106 10.3973 27.3092C9.69374 29.0077 9.33162 
        30.8282 9.33162 32.6667C9.33162 34.5053 9.69374 36.3258 10.3973 38.0243C11.1009 39.7229 12.1321 41.2662 13.4321 42.5662C14.7321 43.8663 16.2755
        44.8975 17.9741 45.6011C19.6726 46.3046 21.4931 46.6667 23.3316 46.6667C27.0447 46.6667 30.6056 45.1918 33.2311 42.5662C35.8566 39.9407 37.3316 
        36.3798 37.3316 32.6667C37.3316 28.9537 35.8566 25.3928 33.2311 22.7673C30.6056 20.1417 27.0447 18.6667 23.3316 18.6667ZM25.665 
        21.0001V23.3334H30.3316V28.0001H20.9983C20.7067 27.9995 20.4256 28.1082 20.2101 28.3046C19.9947 28.501 19.8606 28.771 19.8342
        29.0613C19.8079 29.3517 19.8912 29.6414 20.0678 29.8734C20.2444 30.1053 20.5014 30.2628 20.7883 30.3147L20.9983 30.3334H25.665C27.2121 30.3334 
        28.6958 30.948 29.7897 32.042C30.8837 33.1359 31.4983 34.6197 31.4983 36.1667C31.4983 37.7138 30.8837 39.1976 29.7897 40.2915C28.6958 41.3855 
        27.2121 42.0001 25.665 42.0001V44.3334H20.9983V42.0001H16.3316V37.3334H25.665C25.9565 37.334 26.2377 37.2253 26.4531 37.0289C26.6686 36.8325 
        26.8027 36.5625 26.829 36.2722C26.8553 35.9818 26.772 35.6921 26.5954 35.4601C26.4189 35.2282 26.1618 35.0707 25.875 35.0187L25.665 35.0001H20.9983C19.4512
        35.0001 17.9675 34.3855 16.8735 33.2915C15.7795 32.1976 15.165 30.7138 15.165 29.1667C15.165 27.6197 15.7795 26.1359 16.8735 25.042C17.9675 23.948
        19.4512 23.3334 20.9983 23.3334V21.0001H25.665ZM32.665 9.33342C30.6879 9.3311 28.7329 9.74861 26.9292 10.5583C25.1256 11.368 23.5144 12.5515 22.2023 
        14.0304C24.8396 13.8703 27.4808 14.2719 29.9513 15.2086C32.4218 16.1453 34.6653 17.5959 36.5335 19.4642C38.4017 21.3326 39.8519 23.5763 40.7883
        26.0469C41.7248 28.5176 42.126 31.1588 41.9656 33.7961C44.0871 31.9099 45.5851 29.4233 46.2609 26.6661C46.9366 23.909 46.7583 21.0115 45.7494 
        18.3581C44.7406 15.7046 42.949 13.4206 40.6121 11.8088C38.2753 10.197 35.5037 9.33375 32.665 9.33342Z"
      fill="var(--svgColor)"
    />
  </svg>
);

export default MyLoansIcon;
