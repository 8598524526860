import React from "react";
import { Grid, Typography } from "@material-ui/core";

const ContainerTitle = ({ title, variant, variantAction, customItem }) => {

  return (
    <Grid container alignItems='center' justify='space-between' style={{ paddingTop: 10, paddingLeft: 20, paddingRight: 20 }}>
      <Typography className="whiteText" style={{ fontSize: 25, fontWeight: 500 }}>
        {title}
      </Typography>
      {
        variant &&
        <Typography
          onClick={variantAction} className="whiteText"
          style={{ cursor: 'pointer', textDecoration: 'underline', fontSize: 16, fontWeight: 500 }}
        >
          {'Ver todos'}
        </Typography>
      }
      {customItem}
    </Grid>
  )
}

export default ContainerTitle;