import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { getAssistanceTableRows } from "../actions";
import { getIsExportingGrid } from "src/app/selectors";
import { getAssistanceRows, getDateFormat, getLocale, loading } from "../selectors";
import CommonPage from "@icarius-common/commonPage";
import paths from "@icarius-localization/paths";
import TimeBetweenMarksRenderer from "@icarius-table/renderersAndFilters/timeBetweenMarksRenderer";

const MyAssistance = () => {

  const dispatch = useDispatch();

  const exportingGridStatus = useSelector(getIsExportingGrid);
  const assistanceRows = useSelector(getAssistanceRows);
  const loadingRows = useSelector(loading);
  const dateFormat = useSelector(getDateFormat);
  const locale = useSelector(getLocale);

  useEffect(() => {
    dispatch(getAssistanceTableRows());
  }, [dispatch])

  return (
    <CommonPage
      rowData={assistanceRows}
      title={getLocalizedString("myAssistenceTitle")}
      gridTitle={getLocalizedString("myAssistenceTitle")}
      locale={locale}
      dateFormat={dateFormat}
      columnDefPage={paths.myAssistance}
      isLoading={loadingRows || exportingGridStatus}
      frameworkComponents={{ TimeBetweenMarksRenderer }}
      hasHelp
      hasExpand
      hasSelectAll
    />
  );
}

export default MyAssistance;
