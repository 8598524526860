import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid, Card } from "@material-ui/core";
import paths from "@icarius-localization/paths";
import DataContainer from "@icarius-common/abmComponents/dataContainer";
import { getAlerts } from "../../selectors";
import ContainerTitle from "../containerTitle";

const Alerts = () => {

  const alerts = useSelector(getAlerts);
  const history = useHistory();

  const handleTitleClick = () => {
    history.push(paths.alerts)
  }

  if(!alerts.length) return null;

  return (
    <Grid container>
      <ContainerTitle
        title='Últimas notificaciones'
        variantAction={handleTitleClick}
        variant
      />
      {
        alerts.map(item => {
          return (
            <Card key={item.code} style={{ width: "100%", borderRadius: 10, marginTop: 10 }}>
              <Grid container item xs={12} style={{ padding: "10px 20px" }}>
                <DataContainer sm={12} md={2} lg={2} text={item.alertClass} />
                <DataContainer sm={12} md={8} lg={8} text={item.title} />
                <DataContainer sm={12} md={2} lg={2} text={item.date} />
              </Grid>
            </Card>
          )
        })
      }
    </Grid>
  )
}

export default Alerts;