import React from "react";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import useMyApprovedConsents from "./useMyApprovedConsents";
import ConsentDialog from "@icarius-common/consentDialog";

const MyApprovedConsents = () => {

  const {
    isLoading,
    data,
    dialogIsOpen,
    consentSelected,
    handleOpenDialog,
    handleCloseDialog,
    handlePreview,
  } = useMyApprovedConsents();

  const previewButton = (gridRef) => (
    <CustomIconButton
      title={'Previsualizar plantilla'}
      onClick={() => handlePreview(gridRef)}
      type={"preview"}
    />
  )

  return (
    <CommonPage
      isLoading={isLoading}
      title={'Mis consentimientos aprobados'}
      gridTitle={'Mis consentimientos aprobados'}
      columnDefPage={paths.myApprovedConsents}
      rowData={data}
      handleRowClick={handleOpenDialog}
      menuItems={[previewButton]}
      hasExpand
      hasHelp
      hasSelectAll
    >
      {
        dialogIsOpen &&
        <ConsentDialog
          open={dialogIsOpen}
          data={consentSelected}
          handleClose={handleCloseDialog}
          readOnly
        />
      }
    </CommonPage>
  )
}

export default MyApprovedConsents;