import React from "react";
import { Grid } from "@material-ui/core";
import ContainerTitle from "./containerTitle";

const Container = ({ title, children, variant, variantAction, customItem }) => {

  return (
    <Grid
      container
      className="myDesktopShadow"
      style={{ minHeight: 330, background: 'var(--secondaryBackgroundColor)', borderRadius: 14 }}
    >
      <ContainerTitle
        title={title}
        variant={variant}
        variantAction={variantAction}
        customItem={customItem}
      />
      {children}
    </Grid>
  )
}

export default Container;