import paths from "@icarius-localization/paths";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";

export default [
    {
        pages: [paths.myApprovedConsents],
        config:
        {
            headerName: "Nombre de consentimiento",
            field: "Nombre de consentimiento",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.myApprovedConsents],
        config: {
            headerName: 'Fecha de publicación',
            field: "Fecha de publicación",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.myApprovedConsents],
        config: {
            headerName: 'Fecha de aceptación',
            field: "Fecha de recepción",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.myApprovedConsents],
        config:
        {
            headerName: "Referencia de publicación",
            field: "Referencia de publicación",
            filter: "agTextColumnFilter",
        }
    },
]