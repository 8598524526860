import React from "react";
import { Grid, useMediaQuery } from "@material-ui/core";
import HeaderClock from "./headerClock";
import FirstSection from "./firstSection";
import SecondSection from "./secondSection";
import ThirdSection from "./thirdSection";
import MedalTableButton from "./medalTableButton";

const Header = (props) => {

  const {
    handleOpenRegisterAssistanceDialog,
    handleOpenMyCertificatesDialog,
    handleOpenMyReceiptsDialog,
    handleOpenMyDigitalDocumentsDialog,
    handleOpenMailDialog,
    handleOpenMedalTableDialog,
    userRole,
    userCode,
    hasMedalTable,
  } = props;

  const isMoreThan960 = useMediaQuery(`(min-width: 960px)`);

  return (
    <>
      <HeaderClock />
      <Grid container className="myDesktopHeader myDesktopShadow">
        <Grid container direction="column" item sm={12} md={6}>
          <FirstSection
            handleOpenMailDialog={handleOpenMailDialog}
          />
        </Grid>
        <Grid container item sm={12} md={6}>
          {
            userRole === "C" &&
            <Grid container item xs={12} sm={hasMedalTable ? 6 : 12} justify={isMoreThan960 ? 'flex-start' : 'center'} style={{ paddingLeft: 5 }}>
              <SecondSection
                handleOpenRegisterAssistanceDialog={handleOpenRegisterAssistanceDialog}
              />
            </Grid>
          }
          {
            hasMedalTable &&
            <Grid container item xs={12} sm={userRole === "C" ? 6 : 12} alignItems={'center'} justify="center" direction="column">
              <MedalTableButton userCode={userCode} handleClick={handleOpenMedalTableDialog} />
            </Grid>
          }
        </Grid>
        <Grid container item xs={12}>
          <ThirdSection
            handleOpenMyCertificatesDialog={handleOpenMyCertificatesDialog}
            handleOpenMyReceiptsDialog={handleOpenMyReceiptsDialog}
            handleOpenMyDigitalDocumentsDialog={handleOpenMyDigitalDocumentsDialog}
            userRole={userRole}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default Header;
