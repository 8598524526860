import {
  GET_ASSISTANCE_ROWS,
  GET_ASSISTANCE_ROWS_FULFILLED,
  GET_ASSISTANCE_ROWS_REJECTED,
} from "./actionTypes";

const initialState = {
  assistanceRows: [],
  locale: "es",
  dateFormat: "yyyy/mm/dd",
  gettingAssistanceRows: false,
  helpDocumentation: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ASSISTANCE_ROWS:
      return { ...state, gettingAssistanceRows: true };
    case GET_ASSISTANCE_ROWS_FULFILLED:
      return {
        ...state,
        gettingAssistanceRows: false,
        assistanceRows: action.payload.assistanceRows,
        dateFormat: action.payload.dateFormat,
        helpDocumentation: action.payload.helpDocumentation,
      };
    case GET_ASSISTANCE_ROWS_REJECTED:
      return { ...state, gettingAssistanceRows: false };
    default:
      return state;
  }
}
