import React, { useState } from "react";
import { Typography, Card, Button, Tooltip } from "@material-ui/core";
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import TermsAndConditionsDialog from "./termsAndConditionsDialog";
import _ from "@lodash";
import { previewFromURL } from "@icarius-utils/download";
import { formatNumberExactDecimals } from "@icarius-utils/format";

const Container = ({ children }) => {

  return (
    <div
      style={{
        padding: 10,
        borderRadius: 8,
        border: '1px solid var(--medalTableBorder)',
        background: 'var(--medalTableHeader)',
      }}
    >
      {children}
    </div>
  )
}

const BenefitItem = (props) => {

  const {
    isExchangeForMoney,
    image,
    name,
    value,
    moneyValue,
    requisites,
    description,
    accessMode,
    frecuency,
    link,
    handleRequest,
  } = props;

  const [termsAndConditionsDialogIsOpen, setTermsAndConditionsDialogIsOpen] = useState(false);

  const color = useSelector(getAppColor);

  const handleOpenTermsAndConditions = () => {
    requisites && setTermsAndConditionsDialogIsOpen(true);
  }

  const handleOpenLink = () => {
    const hasProtocol = link.startsWith('https://') || link.startsWith('http://');
    const urlToOpen = `${hasProtocol ? '' : 'http://'}${link}`
    previewFromURL(urlToOpen);
  }

  return (
    <>
      <Card style={{ width: '100%' }}>
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              height: image ? 175 : 0,
              backgroundImage: image ? `url("${IMAGES_ENDPOINT + image}")` : "",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          />
          <div style={{ padding: '0px 15px' }}>
            <Typography className="whiteText" align="center" style={{ fontWeight: 800, fontSize: 20, padding: 18 }}>
              {name}
            </Typography>
            <Container>
              {
                isExchangeForMoney &&
                <div style={{ marginBottom: 10 }}>
                  <Typography className="whiteText" align="center">
                    {'Valor a recibir'}
                  </Typography>
                  <Typography align="center" style={{ fontSize: 22, fontWeight: 600, color: color }}>
                    {formatNumberExactDecimals(moneyValue, 2)}
                  </Typography>
                </div>
              }
              <Typography className="whiteText" align="center">
                {'Puntos requeridos'}
              </Typography>
              <Typography className="whiteText" align="center" style={{ fontSize: 22, fontWeight: 600, }}>
                {formatNumberExactDecimals(value, 0)}
              </Typography>
            </Container>
            <Tooltip title={description}>
              <div style={{ height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography className="whiteText" align="center" style={{ fontSize: 16, padding: '0px 20px' }}>
                  {_.truncate(description, { length: 75 })}
                </Typography>
              </div>
            </Tooltip>
            {
              isExchangeForMoney &&
              <Typography className="whiteText" align="center" style={{ fontWeight: 600, padding: '0px 20px' }}>
                {'* La fecha de pago será determinada por Gestión de Personas'}
              </Typography>
            }
            {
              !isExchangeForMoney &&
              <Container>
                <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', flexDirection: 'column' }}>
                  <div style={{ padding: 10 }}>
                    <Typography className="whiteText" align="center" style={{ fontSize: 14 }}>
                      {'Modo de acceso'}
                    </Typography>
                    <Typography className="whiteText" align="center" style={{ fontSize: 14, fontWeight: 600 }}>
                      {accessMode}
                    </Typography>
                  </div>
                  <div style={{ padding: 10 }}>
                    <Typography className="whiteText" align="center" style={{ fontSize: 14 }}>
                      {'Frecuencia de uso'}
                    </Typography>
                    <Typography className="whiteText" align="center" style={{ fontSize: 14, fontWeight: 600 }}>
                      {frecuency}
                    </Typography>
                  </div>
                </div>
              </Container>
            }
            <div style={{ height: 75, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
              {
                Boolean(requisites) &&
                <Typography
                  onClick={handleOpenTermsAndConditions}
                  className="whiteText"
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    fontSize: 14,
                  }}
                >
                  {'Requisitos y condiciones'}
                </Typography>
              }
              {
                Boolean(link) &&
                <Typography
                  onClick={handleOpenLink}
                  style={{
                    fontStyle: 'italic',
                    cursor: 'pointer',
                    fontWeight: 600,
                    fontSize: 14,
                    color: color,
                    margin: 10,
                  }}
                >
                  {'Conocer más...'}
                </Typography>
              }
            </div>
          </div>
          <div style={{ padding: 15, paddingTop: 0 }}>
            <Button
              fullWidth
              variant="contained"
              style={{ background: color, color: 'white' }}
              onClick={handleRequest}
            >
              {'Solicitar'}
            </Button>
          </div>
        </div>
      </Card>
      {
        termsAndConditionsDialogIsOpen &&
        <TermsAndConditionsDialog
          open={termsAndConditionsDialogIsOpen}
          name={name}
          data={requisites}
          handleClose={() => setTermsAndConditionsDialogIsOpen(false)}
        />
      }
    </>
  )
}

export default BenefitItem;