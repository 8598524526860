import React from "react";
import {
  Typography,
  Grid,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import BenefitItem from "./benefitItem";

const BenefitsList = (props) => {

  const {
    data,
    exchangeForMoneyData,
    handleRequest,
  } = props;

  const color = useSelector(getAppColor);
  
  if(!data) return null;

  const hasExchangeForMoney = Boolean(exchangeForMoneyData.moneyValue);

  return (
    <div style={{ margin: '30px 10px' }}>
      <Typography className="whiteText" style={{ borderBottom: `1px solid ${color}` }}>
        Beneficios que puedes solicitar
      </Typography>
      <Grid container spacing={2} style={{ paddingTop: 10 }}>
        {
          !hasExchangeForMoney && !data.length &&
          <div style={{ width: '100%', marginTop: 20 }}>
            <Typography className="whiteText" align="center" style={{ fontSize: 26 }}>
              {'No hay beneficios disponibles en estos momentos'}
            </Typography>
          </div>
        }
        {
          hasExchangeForMoney &&
          <Grid container item xs={12} sm={6} md={4}>
            <BenefitItem
              isExchangeForMoney={hasExchangeForMoney}
              image={data.image}
              name={'¡Tienes un beneficio con cobro directo en dinero!'}
              value={exchangeForMoneyData.requiredPoints}
              moneyValue={exchangeForMoneyData.moneyValue}
              requisites={null}
              description={'El pago del valor indicado se hará en tu cálculo de nómina'}
              accessMode={null}
              frecuency={null}
              handleRequest={() => handleRequest(null)}
            />
          </Grid>
        }
        {
          data.map((item) => {
            return (
              <Grid key={item.code} container item xs={12} sm={6} md={4}>
                <BenefitItem
                  exchangeForMoney={null}
                  image={item.image}
                  name={item.name}
                  value={item.requiredPoints}
                  requisites={item.requisites}
                  description={item.description}
                  accessMode={item.accessMode}
                  frecuency={item.frecuency}
                  link={item.link}
                  handleRequest={() => handleRequest(item)}
                />
              </Grid>
            )
          })
        }
      </Grid>
    </div>
  )
}

export default BenefitsList;