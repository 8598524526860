import React from "react";
import { Tooltip, useMediaQuery } from "@material-ui/core";
import { MedalTableIcon } from "@icarius-icons/index";
const SIZE = 90;

const MedalTableButton = ({ handleClick }) => {

  const isMoreThan960 = useMediaQuery(`(min-width: 960px)`);

  return (
    <div style={{ paddingTop: isMoreThan960 ? 0 : 20 }}>
      <Tooltip title='Mi medallero'>
        <div
          onClick={handleClick}
          style={{
            background: 'var(--mainBackgroundColor)',
            borderRadius: '100%',
            width:SIZE,
            height:SIZE,
            cursor: 'pointer',
          }}
        >
          <div style={{ height: SIZE, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <MedalTableIcon style={{ fill: 'var(--svgColor)' }} />
          </div>
        </div>
      </Tooltip>
    </div>
  )
}

export default MedalTableButton;
