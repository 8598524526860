import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid, Card, Typography } from "@material-ui/core";
import paths from "@icarius-localization/paths";
import { getAnnotations } from "../../selectors";
import ContainerTitle from "../containerTitle";
import { getCategoryColor, getAnnotationTypeColor } from "@icarius-utils/colors";
import { ErrorIcon } from "@icarius-icons";
import AnnotationText from "@icarius-pages/annotationsPage/components/annotations/annotationText";
import { formatDate, getLocalTimeFromUTCStringDateAndStringTime } from "@icarius-utils/date";
import { withStyles } from "@material-ui/core/styles";
import { getLocalizedString } from "@icarius-localization/strings";
import { getTheme } from "@icarius-pages/login/selectors";

const Annotations = ({ userRole }) => {

  const { theme } = useSelector(getTheme);
  const annotations = useSelector(getAnnotations);
  const history = useHistory();

  const handleTitleClick = () => {
    history.push(paths.myAnnotations)
  }

  const getTypeKey = (value) => {
    if (value === "Demérito") return "DE";
    if (value === "Mérito") return "ME";
    if (value === "Otras") return "OT";
    return "";
  }

  if (!annotations.length) return null;

  return (
    <Grid container>
      <ContainerTitle
        title={`Últimas anotaciones ${userRole !== "C" ? 'que has realizado' : 'recibidas'}`}
        variantAction={handleTitleClick}
        variant={userRole === "C"}
      />
      {
        annotations.map(item => {
          const localDate = getLocalTimeFromUTCStringDateAndStringTime(item.date, item.time);
          const CustomTypography = withStyles({
            root: {
              color: getAnnotationTypeColor(getTypeKey(item.type), theme),
              fontWeight: "bold",
            },
          })(Typography);

          return (
            <Card key={item.code} style={{ width: "100%", borderRadius: 10, marginTop: 10, padding: 10 }}>
              <Grid container item xs={12}>
                <Grid container item justify="flex-start" xs={1} style={{ paddingLeft: 10 }}>
                  <ErrorIcon htmlColor={getCategoryColor(item.category, theme, true)} />
                </Grid>
                <Grid container item xs={11}>
                  <Grid item xs={12} sm={6} md={5}>
                    <AnnotationText isBold text={item.subtype} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <CustomTypography
                        align="justify"
                        variant="subtitle1"
                        className="todo-title truncate">
                        {item.type}
                      </CustomTypography>
                      <AnnotationText shouldtruncate text={(userRole !== "C" ? ", a " : getLocalizedString("by")) + item.name} />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <AnnotationText text={`${formatDate(localDate)} ${localDate.toTimeString().split(' ')[0].substring(0, 5)} hrs`} />
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          )
        })
      }
    </Grid>
  )
}

export default Annotations;