import React, { useState, useMemo } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, List, Typography, ListItem, Select, FormControl, MenuItem } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import AnnotationsListItem from "@icarius-pages/annotationsPage/components/annotations/annotationsListItem";
import useAnnotationYear from "@icarius-pages/annotationsPage/components/annotations/useAnnotationYear";
import Timeline from "@icarius-pages/annotationsPage/components/annotations/timeline";
import AnnotationsChart from "./annotationsChart";
import { createDateFromDDMMYYYY } from "@icarius-utils/date";

const CustomSelect = withStyles({
  select: {
    maxWidth: 300,
    minWidth: 200,
  },
})(Select);

//usa React.memo para no desencadenar la animacion de los graficos en cada cambio
const MyAnnotationsContent = React.memo((props) => {

  const [annotationType, setAnnotationType] = useState({ FldValue: "", Descr: "" });

  const {
    types,
    data,
    gridTheme,
    color,
    getSubtypeName,
    handleViewAnnotation,
  } = props;

  const { year, setYear, yearsArray } = useAnnotationYear(data);

  const handleSelectTipo = (e, types) => {
    const type = types.find(o => o.FldValue === e.target.value);
    setAnnotationType(type || { FldValue: "", Descr: "" });
  }

  const filteredData = useMemo(() => {
    let aux = [...data];

    //filtrar segun año seleccionado
    if (year !== "") {
      aux = aux.filter(annotation => {
        const annotationYear = createDateFromDDMMYYYY(annotation["Fecha de registro"]).getFullYear();
        const formattedSelectedYear = parseInt(year);
        return annotationYear === formattedSelectedYear;
      })
    }

    if (annotationType.FldValue !== "") {
      aux = aux.filter(annotation => annotation["Tipo"] === annotationType.FldValue)
    }

    return aux;
  }, [year, annotationType, data])

  const hasData = data?.length > 0;
  const hasFilteredData = filteredData && filteredData.length > 0;

  const getContentToRender = () => {
    if (!hasFilteredData) {
      return (
        <ListItem>
          <Typography variant="h6" id={"toolbar-title"}>
            {getLocalizedString("noAnnotations")}
          </Typography>
        </ListItem>
      )
    }

    if (year !== '') {
      return (
        <div style={{ marginTop: 10 }}>
          <Timeline
            data={filteredData}
            getSubtypeName={getSubtypeName}
          />
        </div>
      )
    }

    return (
      filteredData.map(item => (
        <AnnotationsListItem
          isMyAnnotation
          gridTheme={gridTheme}
          color={color}
          item={item}
          key={item.Codigo}
          getSubtypeName={getSubtypeName}
          handleViewAnnotation={handleViewAnnotation}
        />
      ))
    )
  }

  return (
    <Grid container style={{ padding: 10, paddingTop: 0 }}>
      {
        hasData &&
        <AnnotationsChart
          annotationRows={data}
          getAnnotationSubtypeName={getSubtypeName}
          gridTheme={gridTheme}
          companyColor={color}
        />
      }
      <Grid
        className={"annotationsPadding"}
        style={data.length === 0 ? { paddingLeft: 0 } : {}}
        item
        xs={12}
        md={data.length > 0 ? 10 : 12}
      >
        {
          hasData &&
          <Grid container justify='space-between'>
            <List className="h-100 backgroundSecondary listAnnotations" style={{ width: "300px", margin: 0, marginBottom: 10 }}>
              <Grid container justify="center" alignItems="center">
                <FormControl style={{ width: "90%" }}>
                  <CustomSelect
                    value={annotationType.FldValue}
                    onChange={e => handleSelectTipo(e, types)}
                    displayEmpty>
                    <MenuItem value="">{getLocalizedString("selectType")}</MenuItem>
                    {
                      types.map((item, index) => (
                        <MenuItem key={index} value={item.FldValue}>
                          {item.Descr}
                        </MenuItem>
                      ))
                    }
                  </CustomSelect>
                </FormControl>
              </Grid>
            </List>
            <List className="h-100 backgroundSecondary listAnnotations" style={{ width: "300px", margin: 0, marginBottom: 10 }}>
              <Grid container justify="center" alignItems="center">
                <FormControl style={{ width: "90%" }}>
                  <CustomSelect
                    value={year}
                    onChange={e => setYear(e.target.value)}
                    displayEmpty>
                    <MenuItem value="">{'Todas las anotaciones'}</MenuItem>
                    {
                      yearsArray.map(item => (
                        <MenuItem key={item.key} value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </CustomSelect>
                </FormControl>
              </Grid>
            </List>
          </Grid>
        }
        <List className="list-round-container" style={{ overflowX: 'hidden' }}>
          {getContentToRender()}
        </List>
      </Grid>
    </Grid>
  );
})

export default MyAnnotationsContent;
