import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";
import {
	numberComparatorMax2,
	numberFormatterMin2,
	numberFormatterMin0Max0,
	numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: ['myBenefits/historical'],
        config: {
            headerName: 'Plan',
            field: 'Plan',
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ['myBenefits/historical'],
        config: {
            headerName: 'Código de empleado',
            field: 'Código de empleado',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['myBenefits/historical'],
        config: {
            headerName: 'Apellido y Nombres',
            field: 'Apellido y Nombres',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['myBenefits/historical'],
        config: {
            headerName: 'Estado',
            field: 'Estado',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['myBenefits/historical'],
        config: {
            headerName: 'Fecha de solicitud',
            field: 'Fecha de solicitud',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: ['myBenefits/historical'],
        config: {
            headerName: 'Puntos canjeados',
            field: 'Puntos canjeados',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ['myBenefits/historical'],
        config: {
            headerName: 'Tipo de canje',
            field: 'Tipo de canje',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['myBenefits/historical'],
        config: {
            headerName: 'Beneficio',
            field: 'Beneficio',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['myBenefits/historical'],
        config: {
            headerName: 'Cantidad',
            field: 'Cantidad',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ['myBenefits/historical'],
        config: {
            headerName: 'Valor del beneficio',
            field: 'Valor del beneficio',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },

]