import React from "react";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  cssOutlinedInput: {
    color: "var(--mainText) !important",
    '&$cssFocused $notchedOutline': {
      color: "var(--mainText) !important",
    }
  },

  cssFocused: {
    color: "var(--mainText) !important",
  },

  notchedOutline: {
    borderColor: 'var(--icons) !important',
    color: "var(--mainText) !important",
  },
});

const TermsAndConditionsDialog = (props) => {

  const {
    open,
    name,
    data,
    classes,
    handleClose,
  } = props;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"md"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {`Requisitos y condiciones de ${name}`}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Grid container item xs={12}>
            <TextField
              disabled
              fullWidth
              label="Requisitos y condiciones"
              variant="outlined"
              multiline
              rows={5}
              margin={"none"}
              value={data}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                }
              }}
              inputProps={{ spellCheck: 'false' }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction isAccept text={'Cerrar'} onClick={handleClose} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default withStyles(styles)(TermsAndConditionsDialog);
