import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { getMyAnnotationRowsFromServer } from "../actions";
import { getMyAnnotationRows, getMyAnnotationSubtypes, getMyAnnotationTypes, loading } from "../selectors";
import { getAppColor } from "src/app/selectors";
import { getTheme } from "@icarius-pages/login/selectors";
import CommonPage from "@icarius-common/commonPage";
import ViewAnnotationDialog from "@icarius-pages/annotationsPage/components/annotations/viewAnnotations/viewAnnotationDialog";
import MyAnnotationsContent from "./content";

const MyAnnotations = () => {

  const [viewAnnotationDialogIsOpen, setViewAnnotationDialogIsOpen] = useState(false);
  const [annotation, setAnnotation] = useState({});

  const dispatch = useDispatch();

  const theme = useSelector(getTheme);
  const data = useSelector(getMyAnnotationRows);
  const types = useSelector(getMyAnnotationTypes);
  const subtypes = useSelector(getMyAnnotationSubtypes);
  const isLoading = useSelector(loading);
  const companyColor = useSelector(getAppColor);

  useEffect(() => {
    dispatch(getMyAnnotationRowsFromServer());
  }, [dispatch])

  const handleCloseViewAnnotationDialog = () => {
    setViewAnnotationDialogIsOpen(false);
    setAnnotation({});
  };

  //tiene useCallback para que el React.memo de MyAnnotationsContent lo tome como que es siempre el mismo
  const handleOpenViewAnnotationDialog = useCallback((annotation) => {
    setViewAnnotationDialogIsOpen(true);
    setAnnotation(annotation);
  }, [])

  //tiene useCallback para que el React.memo de MyAnnotationsContent y el de ChartsContainer lo tome como que es siempre el mismo
  const getAnnotationSubtypeName = useCallback((tipo, subtipo) => {
    let subArray = subtypes[tipo].filter(subtype => subtype.FldValue === subtipo)[0];
    return subArray ? subArray["Descr"] : getLocalizedString("noSubtype");
  }, [subtypes])

  const getAnnotationTypeName = tipo => {
    return types.filter(type => type.FldValue === tipo)[0]["Descr"];
  };

  return (
    <CommonPage
      isNotGridPage
      title={getLocalizedString("myAnnotationsTitle")}
      isLoading={isLoading}
      audioName={"Mis anotaciones"}
    >
      {
        !isLoading &&
        <MyAnnotationsContent
          types={types}
          data={data}
          gridTheme={theme.theme}
          color={companyColor}
          handleViewAnnotation={handleOpenViewAnnotationDialog}
          getSubtypeName={getAnnotationSubtypeName}
        />
      }
      {
        viewAnnotationDialogIsOpen &&
        <ViewAnnotationDialog
          open={annotation && viewAnnotationDialogIsOpen}
          isMyAnnotation
          getTypeName={getAnnotationTypeName}
          getSubtypeName={getAnnotationSubtypeName}
          annotation={annotation}
          handleClose={handleCloseViewAnnotationDialog}
        />
      }
    </CommonPage>
  );
}

export default MyAnnotations;
